import React from 'react';
import { useSelector } from 'react-redux';
import { setBricksData, updateBricks } from '../../redux/sclices/bricks.slice';
import { Dictionary } from '../../consts/dictionary';
import BricksService from '../../services/bricks.service';
import { Page } from '../common/page/page';

export const Bricks = () => {

    const bricksData = useSelector((state) => {
        return state.bricks;
    });


    return (
        <Page data={bricksData}
              pageDictionary={Dictionary.pages.bricks}
              loadMethod={BricksService.loadBricks}
              setDataDispatchMethod={setBricksData}
              updateDispatchMethod={updateBricks}
              createMethod={BricksService.createBrick}
              deleteSingleItem={BricksService.deleteSingleBrick}
              editItemMethod={BricksService.updateBrick}
              bulkDeleteMethod={BricksService.deleteBulkBrick}
        />
    );
}
