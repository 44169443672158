import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loginData: undefined,
}


export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setLoginData: (state, action) => {
            state.loginData = action.payload;
        },
        reset: (state) => {
            state.loginData = undefined;
        }
    },

});

export const { setLoginData, reset } = loginSlice.actions;

export default loginSlice.reducer;
