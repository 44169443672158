import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { RouteTable } from './consts/routeTable';
import { Login } from './components/pages/login/login';
import { Home } from './components/pages/home/home';
import { useDispatch, useSelector } from 'react-redux';
import { getNextAlert } from './redux/sclices/alert.slice';
import { AlertMessage } from './components/common/alertMessage/alertMessage';
import { Authentication } from './components/pages/auth/authentication';
import './App.css';


function App() {
    const dispatch = useDispatch();

    const alert = useSelector((data) => {
        return data.alert.current;
    });
    const alertCount = useSelector((data) => {
        return data.alert.counter;
    });



    return (
        <div className='App'>
            {alert && <AlertMessage alert={alert} alertCount={alertCount} closeHandler={
                () => {
                    dispatch(getNextAlert());
                }
            }/>}
            <BrowserRouter>
                <Routes>
                    <Route path={RouteTable.login} exact element={<Login/>}/>
                    <Route path={`${RouteTable.home}/*`} exact element={<Home/>}/>
                    <Route path={`${RouteTable.auth}/*`} exact element={<Authentication/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
