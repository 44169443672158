import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableColumns: [],
    list: [],
    settings: undefined,
}

export const repositoriesSlice = createSlice({
    name: 'repositories',
    initialState: initialState,
    reducers: {
        setRepositoriesData: (state, action) => {
            state.tableColumns = action.payload.tableColumns || [];
            state.list = action.payload.list || [];
        },
        setRepositoriesSettings: (state, action) => {
            state.settings = action.payload;
        },
        updateRepositories: (state, action) => {
            const updatedRepository = action.payload.current;
            const previousRepositoryData = action.payload.previous;
            state.list = state.list.map(repository => {
                if (repository.id === previousRepositoryData.id) {
                    return {
                        ...repository,
                        ...updatedRepository
                    }
                }
                return repository;
            });
        },
        reset: (state) => {
            state.loginData = {
                tableColumns: [],
                list: [],
                settings: undefined,
            };
        }
    },

});

export const { setRepositoriesData, reset, updateRepositories, setRepositoriesSettings } = repositoriesSlice.actions;

export default repositoriesSlice.reducer;
