import { createSlice } from '@reduxjs/toolkit';
import { Pages } from "../../consts/pages";

const initialState = {
    allPages: [],
    currentPage: undefined,
};

export const pagesSlice = createSlice({
    name: 'pages',
    initialState: initialState,
    reducers: {
        setPages: (state, action) => {
            const roleBasedPages = Pages.filter(page => page.userRole >= action.payload);
            state.allPages = roleBasedPages;
            state.currentPage = roleBasedPages[0];
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        reset: (state) => {
            state.allPages = [];
            state.currentPage = undefined;
        }
    }
});

export const { setPages, setCurrentPage, reset } = pagesSlice.actions;

export default pagesSlice.reducer;
