import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { Colors } from '../../../../../consts/colors.const';
import { Dictionary } from '../../../../../consts/dictionary';

export const SettingsCheckBox = ({disable, checked, onChange}) => {

    return <Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px'}}>
        <Checkbox disabled={disable}
                  size="small"
                  checked={checked}
                  sx={{padding: '0', marginRight: '5px'}}
                  onClick={() => {
                      onChange(!checked);
                  }}/>
        <Typography variant={'subtitle2'}
                    sx={{
                        textTransform: 'capitalize',
                        color: disable ? Colors.grayedOut : Colors.dark

                    }}>
            {Dictionary.mandatory}
        </Typography>
    </Box>
}
