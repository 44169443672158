import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SettingsField } from '../../../enums/settingsField.enum';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';
import { OptionSelector } from './singleSetting/optionSelector/optionSelector';
import { SettingsText } from './singleSetting/settingsText/settingsText';
import { SettingsCheckBox } from './singleSetting/settingsCheckBox/settingsCheckBox';
import { SettingsDefaultField } from './singleSetting/settingsDefaultField/settingsDefaultField';

const options = Object.keys(SettingsField).map(key => {
    return {
        name: key,
        value: SettingsField[key].toString()
    }
})

export const SettingsDefinition = ({currentSettings, onSubmit}) => {

    const [settingKeyError, setSettingKeyError] = useState(false);

    const [selectedOption, setSelectedOption] = useState({
        isMandatory: false,
        type: SettingsField.text,
        defaultValue: ''
    });

    useEffect(() => {
        if (!selectedOption && options[0]) {
            setSelectedOption({
                type: options[0].value,
                name: '',
                selectOptions: undefined,
            });
        }
        return () => {

        };
    }, [options]);

    const getDefaultValue = () => {
        switch (selectedOption.type) {
            case SettingsField.boolean:
                return 'true';
            case SettingsField.text:
                return '';
            case SettingsField.select:
                return selectedOption.selectOptions[0];
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            height: '350px',
        }}>

            <Typography variant={'h6'}
                        sx={{
                            color: Colors.mainHighlight,
                            cursor: 'default',
                            textTransform: 'capitalize',
                            height: 'fit-content',
                            marginBottom: '15px'
                        }}>
                {Dictionary.createSettings}
            </Typography>

            <SettingsText error={settingKeyError}
                          value={selectedOption?.name || ''}
                          label={Dictionary.settingName}
                          onChange={(value) => {
                              setSettingKeyError(Boolean(currentSettings.find(s => s.name === value)));
                              setSelectedOption({...selectedOption, name: value});
                          }}/>


            <OptionSelector selectedType={selectedOption?.type}
                            selectOptions={selectedOption?.selectOptions}
                            onOptionEditChange={(selectionOptions) => {
                                const defaultValue = (selectionOptions.find(selection =>
                                    selection === selectedOption.defaultValue) ? selectedOption.defaultValue :
                                    selectionOptions[0]) || '';

                                setSelectedOption({
                                    ...selectedOption,
                                    selectOptions: selectionOptions,
                                    defaultValue,
                                    isMandatory: selectedOption.isMandatory && Boolean(defaultValue)
                                });
                            }}
                            options={options}
                            error={
                                selectedOption?.type === SettingsField.select &&
                                !selectedOption?.selectOptions?.length}
                            onChange={(type) => {
                                setSelectedOption({
                                    ...selectedOption,
                                    type,
                                    defaultValue: '',
                                    selectOptions: type === SettingsField.select ? [] : undefined,
                                    isMandatory: false,
                                });
                            }}/>

            <SettingsCheckBox checked={selectedOption?.isMandatory}
                              onChange={(isMandatory) => {
                                  setSelectedOption({
                                      ...selectedOption,
                                      isMandatory,
                                      defaultValue: getDefaultValue(),
                                  })
                              }}
                              disable={selectedOption.type === SettingsField.select
                                  && !selectedOption?.selectOptions?.length}/>


            {selectedOption?.isMandatory &&
                <Box sx={{marginTop: '0'}}>
                    <SettingsDefaultField selectOptions={selectedOption.selectOptions}
                                          defaultValue={selectedOption.defaultValue}
                                          selectedType={selectedOption.type}
                                          onChange={(defaultValue) => {
                                              setSelectedOption({
                                                  ...selectedOption,
                                                  defaultValue,
                                              })
                                          }}/>
                </Box>
            }

            <Button sx={{marginTop: 'auto', width: '100%'}}
                    variant={'contained'}
                    onClick={() => {
                        setSelectedOption({
                            isMandatory: false,
                            type: SettingsField.text,
                            defaultValue: ''
                        });
                        onSubmit({
                            ...selectedOption,
                            defaultValue: selectedOption.isMandatory ? selectedOption.defaultValue : undefined
                        });
                    }}
                    disabled={
                        settingKeyError ||
                        !selectedOption.name ||
                        (selectedOption.isMandatory &&
                            !selectedOption.defaultValue) ||
                        (selectedOption.type === SettingsField.select &&
                            !selectedOption.selectOptions?.length)}>{Dictionary.addSingleSetting}</Button>
        </Box>);
}
