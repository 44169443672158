import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTeam } from '../../../redux/sclices/teamsSlice';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Dictionary, DictionaryWithParam } from '../../../consts/dictionary';
import { Colors } from '../../../consts/colors.const';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import teamsService from "../../../services/teams.service";
import { TextFieldWithRegexValidation } from '../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';
import { Spinner } from '../../common/spinner/spinner';
import { TabMessage } from '../../common/tabMessage/tabMessage';

export const TeamName = 'name';
export const GitLabGroupId = 'id';
export const Description = 'description';

export const TeamsEditor = ({ onSubmit, team }) => {

    const dispatch = useDispatch();

    const [message, setMessage] = useState('');

    const [state, setState] = useState({
        showSpinner: false,
        disableSubmit: !team?.name || !team?.id,
        [TeamName]: team?.name || '',
        [GitLabGroupId]: team?.id || '',
        [Description]: team?.description || '',
    });

    const informUser = (teamNameChanged, teamDescriptionChanged, newData) => {
        let message = '';
        if (teamNameChanged && teamDescriptionChanged) {
            message = DictionaryWithParam(Dictionary.teamNameAndDescriptionChanged, {
                TEAM_NAME: team.name,
                NEW_TEAM_NAME: newData.name
            });
        } else if (teamNameChanged) {
            message = DictionaryWithParam(Dictionary.teamNameChanged, {
                TEAM_NAME: team.name,
                NEW_TEAM_NAME: newData.name
            });
        } else if (teamDescriptionChanged) {
            message = DictionaryWithParam(Dictionary.teamDescriptionChanged, {
                TEAM_NAME: team.name,
            });
        }
        if (message) {
            setMessage(message);
        }
    }

    useEffect(() => {
        if (team) {
            setState({...state, showSpinner: true});
            teamsService.getTeam(team.id).subscribe((data) => {

                const teamNameChanged = data.name !== team.name;
                const teamDescriptionChanged = data.description !== team.description;

                if (teamDescriptionChanged || teamNameChanged) {
                    informUser(teamNameChanged, teamDescriptionChanged, data);

                    dispatch(updateTeam({
                        current: data,
                        previous: team,
                    }));
                }
                setState({
                    ...state,
                    [TeamName]: data.name,
                    [GitLabGroupId]: data.id,
                    [Description]: data.description,
                    showSpinner: false});
            }, () => {
                setMessage(Dictionary.failedToGetLatestTeamDetails);
                setState({...state, showSpinner: false});
            });
        }
        return () => {};
    }, [team]);


    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[TeamName]
                || !newState[GitLabGroupId]
                || !(newState[GitLabGroupId]?.toString() || '').match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [GitLabGroupId]: eventData.get(GitLabGroupId),
            [TeamName]: eventData.get(TeamName),
            [Description]: eventData.get(Description),
        });
    }

    return (
        <Box sx={{
            display: 'flex',
            height: '100%'
        }}>
            <TabMessage message={message} onClose={() => {setMessage('')}}/>
            {
                state.showSpinner && <Spinner label={Dictionary.loading}/>
            }
            <Box component='form'
                 onSubmit={submitHandler}
                 sx={{display: 'flex', flexDirection: 'column'}}>
                <TextField required={true}
                           value={state[TeamName]}
                           onInput={(e) => {
                               inputChangeHandler(TeamName, e.target.value);
                           }}
                           name={TeamName}
                           sx={{
                               paddingBottom: '10px',
                               maxWidth: '500px'
                           }}
                           label={Dictionary.teamName}
                           variant="filled" />
                <TextFieldWithRegexValidation value={state[GitLabGroupId]}
                                              disabled={Boolean(team)}
                                              onChange={(value) => {
                                                  inputChangeHandler(GitLabGroupId, value);
                                              }}
                                              onError={(value) => {
                                                  inputChangeHandler(GitLabGroupId, value);
                                              }}
                                              name={GitLabGroupId}
                                              label={Dictionary.id}/>
                <TextField required={true}
                           value={state[Description]}
                           onInput={(e) => {
                               inputChangeHandler(Description, e.target.value);
                           }}
                           name={Description}
                           sx={{
                               paddingBottom: '10px',
                               maxWidth: '500px'
                           }}
                            label={Dictionary.description}
                            multiline
                            rows={4}
                            variant="filled"
                            />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        mt: 3,
                        mb: 2,
                        width: '500px',
                        background: state.disableSubmit ? Colors.disableBackground : '',
                        color: state.disableSubmit ? Colors.grayedOut : '',
                        ':hover': {
                            background: state.disableSubmit ? Colors.disableBackground : '',
                        }
                    }}>
                    {Dictionary.submit}
                </Button>
            </Box>
        </Box>);
}
