import React from 'react';
import {Alert, AlertTitle, Badge, Collapse, IconButton, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { Colors } from '../../../consts/colors.const';

export const AlertMessage = ({alert, alertCount, closeHandler}) => {

    return (
        <Collapse in={!!alert} className='app-alert-message-wrapper' sx={{zIndex: (theme) => theme.zIndex.drawer + 2}}>
            <Badge badgeContent={alertCount} color="primary">
                <Alert className='app-alert-message'
                       severity={alert.severity}
                       action={
                           <Container sx={{display: 'flex', flexDirection: 'column'}}>
                               <IconButton
                                   aria-label="close"
                                   color="inherit"
                                   size="small"
                                   onClick={() => {
                                       closeHandler();
                                   }}>
                                   <CloseIcon fontSize="inherit"/>
                               </IconButton>
                               {alert.action &&
                                   <Tooltip title={alert.action.tooltip} placement='top-start'>
                                       <IconButton
                                           sx={{
                                               ':hover': {color: Colors.mainHighlight}
                                           }}
                                           aria-label="close"
                                           color="inherit"
                                           size="small"
                                           onClick={alert.action.callback}>
                                           {alert.action.icon}
                                       </IconButton>
                                   </Tooltip>
                               }
                           </Container>

                       }
                       sx={{mb: 2}}>
                    <AlertTitle>{alert.title}</AlertTitle>
                    {alert.subTitle}
                </Alert>
            </Badge>
        </Collapse>
    );
}
