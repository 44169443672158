import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../../../consts/colors.const';
import { Dictionary } from '../../../../consts/dictionary';
import { SettingsField } from '../../../../enums/settingsField.enum';
import { SettingRow } from './settingRow/settingRow';

const SettingsNameMap = Object.keys(SettingsField).reduce((total, key) => {
    return {...total, [SettingsField[key]]: key};
}, {})

export const BrickSettings = ({settings = [], onDelete}) => {

    return <Box sx={{width: '100%'}}>
        <Typography variant={'h6'}
                    sx={{
                        color: Colors.mainHighlight,
                        cursor: 'default',
                        textTransform: 'capitalize',
                        height: 'fit-content',
                        marginBottom: '15px'
                    }}>
            {Dictionary.currentSettings}
        </Typography>
        <Box sx={{maxHeight: 'calc(100% - 150px)', overflow: 'scroll'}}>
            {settings.map((setting, index) =>
                <Box key={index} sx={{marginBottom: '10px'}}>
                    <SettingRow setting={setting}
                                settingsNameMap={SettingsNameMap}
                                onDelete={() => {
                                    onDelete(setting);
                                }}/>
                </Box>
            )}
        </Box>
    </Box>
}
