import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import { Dictionary, DictionaryWithParam } from '../../../consts/dictionary';
import tenantsService from "../../../services/tenants.service";
import { TenantMeta } from './tenantMeta/tenantMeta';
import { updateTenant } from '../../../redux/sclices/tenantsSlice';
import { Spinner } from '../../common/spinner/spinner';
import { TabMessage } from '../../common/tabMessage/tabMessage';
import * as _ from 'lodash';

export const TenantName = 'name';
export const GitLabGroupId = 'id';
export const TEAM = 'team';
export const Description = 'description';

export const TenantsEditor = ({ onSubmit, tenant }) => {

    const dispatch = useDispatch();

    const getInitState = () => {
        return {
            disableSubmit: !tenant?.name || !tenant?.id,
            [TenantName]: tenant?.name,
            [GitLabGroupId]: tenant?.id,
            [TEAM]: tenant?.team,
            [Description]: tenant?.description,
        };
    }

    const [state, setState] = useState(getInitState());

    const [didInit, setDidInit] = useState(false);

    const [subs, setSubs] = useState([]);

    const [changeMessage, setChangeMessage] = useState('');

    const [showSpinner, setShowSpinner] = useState(Boolean(tenant));

    const loadTenantLatestDetails = () => {
        return tenantsService.getTenant(tenant?.id).subscribe((updatedTenant) => {

            dispatch(updateTenant({
                current: _.cloneDeep(updatedTenant),
                previous: tenant
            }));

            if (tenant?.name !== updatedTenant.name ||
                tenant?.team?.id !== updatedTenant.team?.id) {
                setChangeMessage(DictionaryWithParam(Dictionary.tenantChangeMessage, {TENANT_NAME: tenant.name}));
            }

            tenant = updatedTenant;
            setState(getInitState());
            setShowSpinner(false);
        });
    }

    (() => {
        if (didInit) { return; }
        setDidInit(true);
        const addedSubs = [];

        if (tenant) {
            addedSubs.push(loadTenantLatestDetails());
        }

        if (addedSubs.length > 0) {
            setSubs([...subs, ...addedSubs]);
        }
    })();

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[TenantName]
                || !newState[TEAM]
                || !newState[GitLabGroupId]
                || !newState[GitLabGroupId]?.toString().match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [Description]: state[Description],
            [GitLabGroupId]: eventData.get(GitLabGroupId),
            [TenantName]: eventData.get(TenantName),
            [TEAM]: state[TEAM],
        });
    }

    return (
        <Box sx={{
            display: 'flex',
            height: '100%'
        }}>

            {showSpinner && <Spinner label={Dictionary.loading}/> }

            <TabMessage message={changeMessage} onClose={() => {setChangeMessage('')}}/>

            <TenantMeta submitHandler={submitHandler}
                        disableId={Boolean(tenant)}
                        inputChangeHandler={inputChangeHandler}
                        disableSubmit={state.disableSubmit}
                        gitLabGroupId={state[GitLabGroupId]}
                        tenantName={state[TenantName]}
                        description={state[Description]}
                        selectedTeam={state[TEAM]}/>
        </Box>);
}
