import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import * as ICONS from '@mui/icons-material';
import { setBricksData } from '../../../../../redux/sclices/bricks.slice';
import { LoadingStatus } from '../../../../../enums/loadingStatus.enum';
import { Colors } from '../../../../../consts/colors.const';
import { BRICKS } from '../../pipelineEditor';
import BricksService from '../../../../../services/bricks.service';
import { Spinner } from '../../../../common/spinner/spinner';

export const BricksBank = () => {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        subs: [],
        loadingBricksStatus: LoadingStatus.UnKnown,
    });

    const bricksData = useSelector((state) => {
        return state.bricks;
    });

    useEffect(() => {
        if (!bricksData || bricksData.tableColumns?.length === 0) {
            setState({
                ...state,
                loadingBricksStatus: LoadingStatus.InProgress,
                subs: [
                    ...state.subs,
                    BricksService.loadBricks().subscribe(
                        (data) => {
                            setState({...state, loadingBricksStatus: LoadingStatus.Succeed})
                            dispatch(setBricksData(data));
                        },
                        (error) => {
                            setState({...state, loadingBricksStatus: LoadingStatus.Failed})
                        })
                ],
            });
        }

        return () => {
            state.subs.forEach(s => s.unsubscribe());
        }
    }, []);

    const onDragStart = (event, brick) => {
        event.dataTransfer.setData(BRICKS, JSON.stringify(brick));
    }

    return (<Box sx={{width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap',overflowY: 'scroll'}}>
        {state.loadingBricksStatus === LoadingStatus.InProgress && <Spinner/>}
        {bricksData?.list?.map((brick, index) =>
            <Paper key={index} sx={{
                width: 'fit-content',
                height: 'fit-content',
                color: Colors.mainHighlight,
                padding: '10px',
                margin: '10px',
                display: 'flex',
                cursor: 'grab',}}
                   draggable={true} onDragStart={(event) => {onDragStart(event, brick)}}>
                <Box sx={{color: Colors.mainHighlight}} component={ICONS[brick.icon] || SettingsSuggestIcon}/>
                <Typography sx={{textTransform: 'capitalize', fontSize: '15px', marginLeft: '7px'}}>{brick.name}</Typography>
            </Paper>)}
    </Box>);
}
