import React from 'react';
import { Badge, Menu, MenuItem, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Typography from '@mui/material/Typography';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '../../../../consts/dictionary';
import { useNavigate } from 'react-router-dom';
import { AlertType } from '../../../../consts/alert.type';
import { RouteTable } from '../../../../consts/routeTable';
import { reset as resetLogin } from '../../../../redux/sclices/login.slice';
import { reset as resetAlerts } from '../../../../redux/sclices/alert.slice';
import { reset as resetPages } from '../../../../redux/sclices/pages.slice';
import { reset as resetTenants } from '../../../../redux/sclices/tenantsSlice';
import { reset as resetBricks } from '../../../../redux/sclices/bricks.slice';
import { reset as resetPipelines } from '../../../../redux/sclices/pipelines.slice';
import { reset as resetRepositories } from '../../../../redux/sclices/repositories.slice';

import geHcIcon from '../../../../assets/ge.svg'
import defaultAvatar from '../../../../assets/defaultAvatar.png';
import * as style from './style';

const RESET_SLICES = [
    resetLogin,
    resetAlerts,
    resetPages,
    resetTenants,
    resetBricks,
    resetPipelines,
    resetRepositories
]

export const Header = ({avatar}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginData = useSelector((state) => {
        return state.login.loginData;
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        handleClose();
        RESET_SLICES.forEach(slice => {
            dispatch(slice());
        });
       /* AuthenticationService.logout().subscribe((data) => {
            console.log(data);
        }, (e) => {
            console.log(e);
        } )*/
        navigate(RouteTable.login);
    }

    return (
        <Paper sx={style.headerWrapper}>
            <Typography sx={style.headerAlignment}>
                <img src={geHcIcon} alt='GEHC Logo'/>
                <label style={style.headerGEName}>
                    {Dictionary.geHealthcare}
                </label>
            </Typography>

            {/*<Badge badgeContent={0}
                   color={AlertType.info}
                   sx={style.headerRight}
                   overlap="circular" >
                <NotificationsOutlinedIcon sx={style.headerAction}/>
            </Badge>*/}
            <Avatar alt='user' src={avatar || defaultAvatar}
                    sx={style.userAvatar}
                    onClick={handleClick}/>

            <Menu anchorEl={anchorEl}
                  open={open}
                  sx={style.menu}
                  onClose={handleClose}>
                <Typography sx={style.menuUserName}
                            variant="caption"
                            gutterBottom>
                    {`${loginData?.username}`}
                </Typography>
                <MenuItem disabled={true} onClick={logout} sx={style.menuItem}>
                    {Dictionary.logout}
                    <PowerSettingsNewOutlinedIcon sx={{fontSize: '15px', marginLeft: 'auto'}}/>
                </MenuItem>
            </Menu>
        </Paper>
    )
}

