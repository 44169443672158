import React from 'react';
import { SettingsField } from '../../../../../enums/settingsField.enum';
import { Box, FormControl, InputLabel, MenuItem, Radio, Select, TextField, Typography } from '@mui/material';
import { Dictionary } from '../../../../../consts/dictionary';

export const SettingsDefaultField = ({selectedType, defaultValue, selectOptions, onChange}) => {
    
    return <Box sx={{marginTop: '25px'}}>
        {selectedType === SettingsField.text &&
            <TextField required={true}
                       onKeyPress={(event) => {
                           if (event.key === 'Enter') {
                               event.stopPropagation();
                               event.preventDefault();
                           }
                       }}
                       error={!defaultValue}
                       label={Dictionary.defaultValue}
                       variant="standard"
                       value={defaultValue}
                       onChange={(e) => {
                           const value = e?.target?.value || '';
                           onChange(value);
                       }}/>
        }

        {selectedType === SettingsField.boolean &&
            <Box>
                <Typography sx={{ fontSize: '12px', marginBottom: '5px' }}>
                    {Dictionary.defaultValue}:
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ marginRight: '10px' }}>
                        <Typography sx={{ fontSize: '10px', textTransform: 'capitalize' }}
                                    variant={'caption'}>
                            {Dictionary.true}
                        </Typography>

                        <Radio sx={{padding: '0'}}
                               checked={defaultValue === 'true'}
                               onClick={() => {
                                   onChange('true');
                               }}/>

                    </Box>

                    <Box>
                        <Typography sx={{fontSize: '10px', textTransform: 'capitalize'}}
                                    variant={'caption'}>
                            {Dictionary.false}
                        </Typography>

                        <Radio sx={{padding: '0'}}
                               checked={defaultValue === 'false'}
                               onClick={() => {
                                   onChange('false');
                               }}/>
                    </Box>
                </Box>
            </Box>
        }

        {selectedType === SettingsField.select &&
            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                <FormControl>
                    <InputLabel sx={{marginLeft: '-15px'}}
                                id='select-default-value'>
                        {Dictionary.defaultValue}
                    </InputLabel>

                    <Select sx={{width: '200px'}}
                            variant={'standard'}
                            labelId='select-default-value'
                            value={defaultValue || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                onChange(value);
                            }}>

                        {selectOptions?.map((item) =>
                            <MenuItem
                                key={item}
                                value={item}>
                                {item}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>

            </Box>

        }
    </Box>
}
