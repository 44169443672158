import React, {useEffect, useState} from 'react';
import { Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { SHTableHead } from './SHTableHead/SHTableHead';
import { SHTableRow } from './SHTableRow/SHTableRow';
import { Dictionary } from '../../../consts/dictionary';

export const CHECKBOX = 'checkbox';
export const COLLAPSE = 'collapse';
export const ROW_ACTIONS = 'row-actions';

export const SHTable = ({
                            columns,
                            rows,
                            addCheckbox,
                            addCollapse,
                            bulkActions,
                            rowActions,
                            onSelectionChanged,
                            addItemHandler,
                            searchText,
                        }) => {

    const [state, setState] = useState({
        columns: [],
        rows: [],
        checkedRows: []
    });

    useEffect(() => {
        const _state = addOptions({...state, columns: columns, rows: rows});
        setState({
            ..._state,
        });
    }, [rows, columns])

    const addCheckBoxOption = (_state) => {
        const checkedRows = [];
        rows.forEach(r => {
            if (r.check) {
                checkedRows.push(r);
            }
        });
        _state.checkedRows.forEach(r => {
            if (!checkedRows.find(row => row === r)) {
                checkedRows.push(r);
            }
        })
        _state = {
            ..._state,
            checkedRows
        };
        const hasCheckboxCol = _state.columns.find(col => col.id === CHECKBOX);
        if (!hasCheckboxCol) {
            _state = {
                ..._state,
                columns: [{ id: CHECKBOX, label: '' }, ..._state.columns],
            }
        }
        return _state;
    }

    const addCollapseOption = (_state) => {
        const hasCollapseCol = _state.columns.find(col => col.id === COLLAPSE);
        if (!hasCollapseCol) {
            _state = {
                ..._state,
                columns: [{id: COLLAPSE, label: ''}, ..._state.columns],
            };
        }
        return _state;
    }

    const addRowActions = (_state) => {
        const hasRowActionsCol = _state.columns.find(col => col.id === ROW_ACTIONS);
        if (!hasRowActionsCol) {
            _state = {
                ..._state,
                columns: [..._state.columns, {id: ROW_ACTIONS, label: ''}]
            }
        }
        return _state;
    }

    const addOptions = (state) => {
        let _state = state;
        if (addCheckbox) {
            _state = {..._state, ...addCheckBoxOption(_state)};
        }
        if (addCollapse) {
            _state = {..._state, ...addCollapseOption(_state)};
        }

        if (rowActions) {
            _state = {..._state, ...addRowActions(_state)};
        }

        return _state;
    }



    const getRowsPool = () => {
        const rows = state.rows;
        const checkedRows = state.checkedRows;
        return [...rows, ...checkedRows.filter(r => !rows.find(row => row === r))];
    }


    const onCheckAllClick = () => {
        const allChecked = !state.rows.find(r => !r.check)
        state.rows.forEach(row => row.check = !allChecked);
        const pool = getRowsPool();
        const checkedRows = pool.filter(row => row.check);
        setState({
            ...state,
            rows: state.rows,
            checkedRows,
        });
        onSelectionChanged(checkedRows);
    }

    const onSingleRowCheckboxClick = (row) => {
        const newRowCheckState = !row.check;
        const checkedRows = newRowCheckState ? [...state.checkedRows, row] :
            state.checkedRows.filter(elm => elm !== row)
        setState({
            ...state,
            rows: state.rows.map(r => {
                if (r === row) {
                    r.check = newRowCheckState;
                }
                return r;
            }),
            checkedRows,
        })
        onSelectionChanged(checkedRows);
    }
    return(
        <TableContainer component={Paper} sx={{
            border: '1px solid lightgray',
            boxSizing: 'border-box',
            overflow: 'auto',
            maxHeight: 'calc(100% - 240px)'
        }}>
            {(state.rows.length === 0 && searchText) &&
                <Typography variant='h5' sx={{
                    textAlign: 'center',
                    color: 'rgba(0, 0, 0, 0.6)'
                }}>{Dictionary.noMatchItems} "{searchText}"</Typography>}

                <Table>
                    <SHTableHead rows={state.rows}
                                 addItem={addItemHandler}
                                 bulkActions={bulkActions}
                                 showBulkActions={state.checkedRows.length > 0}
                                 columns={state.columns}
                                 checkedRows={state.checkedRows}
                                 onSelectAll={onCheckAllClick}/>
                    <TableBody>

                        {state.rows.length > 0 && state.rows.map((row, index) =>
                            <SHTableRow key={`row_${index}`}
                                        rowActions={rowActions}
                                        row={row}
                                        columns={state.columns}
                                        hasCollapse={addCollapse}
                                        _key={index}
                                        onCheckBoxClick={() => {
                                            onSingleRowCheckboxClick(row);
                                        }}/>)}
                    </TableBody>
                </Table>
        </TableContainer>
    );
}
