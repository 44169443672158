import React, { useState, useEffect } from 'react';
import {
    Box, FormControl, FormControlLabel, MenuItem, Radio,
    RadioGroup, Select, TextField, Tooltip, Typography
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteRounded, EditOff, EditRounded } from '@mui/icons-material';
import { Colors } from '../../../../consts/colors.const';
import { isNullOrUndefined } from '../../../../consts/static.methods.const';
import { SettingsField } from '../../../../enums/settingsField.enum';

const ActionIconSX = {
    color: Colors.dark,
    width: '18px',
    cursor: 'pointer',
    ':hover': {
        color: Colors.mainHighlight
    }
};

export const SelectedSetting = ({setting, onDelete, onEdit, index}) => {
    const [showActions, setShowActions] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState(setting.value || '');

    const windowClickEventListener = () => {
        if (editMode) {
            handleEdit();
        }
    }

    const onOtherSelectSettingGetToEditMode = (e) => {
        if (e.customId !== index && editMode) {
            handleEdit();
        }
    }

    useEffect(() => {
        /*window.addEventListener('click', windowClickEventListener);
        window.addEventListener('setting-in-edit', onOtherSelectSettingGetToEditMode);
        return () => {
            window.removeEventListener('click', windowClickEventListener)
            window.removeEventListener('setting-in-edit', onOtherSelectSettingGetToEditMode)
        };*/
    }, [editMode]);


    const handleEditItemValue = (value) => {
        setEditValue(value);
    }

    const fireCustomEvent = () => {
        /*const customEvent = document.createEvent('Event');
        customEvent?.initEvent('setting-in-edit', true, true);
        customEvent.customId = index;
        const elm = document.getElementById(`selection-box-${index}`);
        elm?.dispatchEvent(customEvent);*/
    }

    const handleEdit = () => {
        if (editMode) {
            setEditValue('');
        } else {
            setEditValue(isNullOrUndefined(setting.value) ? '' : setting.value);
        }
        setEditMode(!editMode);
        if (!editMode) {
            fireCustomEvent();
        }
    }

    const submitEdit = () => {
        onEdit({...setting, value: editValue, touched: true});
        handleEdit();
    }

    return (
        <Box id={`selection-box-${index}`} onClick={(e) => {
            e.stopPropagation();
        }} sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: setting.touched ? Colors.acceptedColor : Colors.grayedOut,
            cursor: 'context-menu',
            position: 'relative',
            margin: '0 4px',
            padding: '0 35px',
            boxSizing: 'border-box',
            ':hover': {
                color: Colors.dark
            }
        }}
             onMouseLeave={() => { setShowActions(false); }}
             onMouseEnter={() => { setShowActions(true); }}>

            {(showActions) &&
                <Box sx={{
                    alignSelf: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '-15px',
                    top: '0',
                    padding: '1px 5px',
                    borderRadius: '15px',
                }}>

                    {!setting.isMandatory &&
                        <DeleteRounded sx={ActionIconSX} onClick={onDelete}/>
                    }
                    {editMode && <EditOff sx={ActionIconSX} onClick={handleEdit}/>}
                    {!editMode && <EditRounded sx={ActionIconSX} onClick={handleEdit}/>}
                </Box>
            }
            <Typography variant='h6'>
                {setting?.name}
            </Typography>
            {!editMode &&
                <Typography variant='h6' sx={{marginLeft: '10px'}}>
                    {setting?.value?.length > 20 ?
                        <Tooltip title={setting.value}>
                            <Box>
                                {setting?.value?.substring(0, 10)}...{setting?.value?.substring(setting.value.length - 5)}
                            </Box>
                        </Tooltip>
                        : setting?.value}
                </Typography>
            }
            {editMode &&
                <>
                    {setting.type === SettingsField.boolean &&
                        <FormControl>
                            <RadioGroup
                                onChange={(e, v) => {
                                    handleEditItemValue(v)
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value={true} control={<Radio/>} checked={editValue === 'true'} label="True" />
                                <FormControlLabel value={false} control={<Radio/>} checked={editValue === 'false'} label="False" />
                            </RadioGroup>
                        </FormControl>
                    }

                    {setting?.type === SettingsField.text &&
                        <TextField variant="standard"
                                   onKeyPress={(event) => {
                                       if (event.key === 'Enter') {
                                           submitEdit();
                                       }
                                   }}
                                   sx={{width: '300px', marginLeft: '20px'}}
                                   value={editValue}
                                   onChange={(e) => {
                                       handleEditItemValue(e.target.value);
                                   }}/>
                    }

                    {setting?.type === SettingsField.select &&
                        <FormControl variant="outlined"
                                     onClick={(e) => {
                                         e.stopPropagation();
                                     }}
                                     sx={{
                                         width: '200px',
                                     }}>
                            <Select variant={'standard'}
                                    value={editValue}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        setEditValue(val)
                                    }}>

                                {setting.selectOptions.map((op, key) =>
                                    <MenuItem
                                        key={key}
                                        value={op}>
                                        {op}
                                    </MenuItem>
                                )}

                            </Select>
                        </FormControl>
                    }

                    <Box sx={{
                        marginRight: '30px',
                        width: '30px'
                    }}>
                        {editMode && editValue &&
                            <AddCircleOutlineIcon
                                onClick={() => {
                                    submitEdit();
                                }}
                                sx={{
                                    color: Colors.mainHighlight,
                                    alignSelf: 'center',
                                    cursor: 'pointer'
                                }}/>
                        }
                    </Box>
                </>
            }
        </Box>
    );
}
