import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import { Block } from '@mui/icons-material';
import { Colors } from '../../../consts/colors.const';
import { Dictionary, DictionaryWithParam } from '../../../consts/dictionary';
import { useDispatch, useSelector } from 'react-redux';
import { getBricksSettings } from '../../../consts/static.methods.const';
import repositoriesService from '../../../services/repositories.service';
import tenantsService from '../../../services/tenants.service';
import { RepositorySettings } from './repositorySettings/repositorySettings';
import { setRepositoriesSettings, updateRepositories } from '../../../redux/sclices/repositories.slice';
import { setTenantsData } from '../../../redux/sclices/tenantsSlice';
import { BranchSettings } from './branchSettings/branchSettings';
import { RepositoryPipelineSelector } from './repositoryPipelineSelector/repositoryPipelineSelector';
import { TabMessage } from '../../common/tabMessage/tabMessage';
import { Spinner } from '../../common/spinner/spinner';
import * as _ from 'lodash';


export const NAME = 'name';
export const BRANCHES = 'branches';
export const TENANT = 'tenant';
export const PIPELINE = 'pipeline';
export const Description = 'description';

const TAB = {
    repoSettings: 0,
    repoPipeline: 1,
    repoBranches: 2,
}

export const RepositoriesEditor = ({repository, onSubmit}) => {

    const dispatch = useDispatch();

    const [selectedTab, setValue] = useState(TAB.repoSettings);

    const [subs, setSubs] = useState([]);

    const [didInit, setDidInit] = useState(false);

    const [changeMessage, setChangeMessage] = useState('');

    const [showSpinner, setShowSpinner] = useState(Boolean(repository));

    const getStateInit = (repo) => {
        return {
            [NAME]: repo?.name || '',
            [BRANCHES]: repo?.branches,
            [TENANT]: repo?.tenant,
            [PIPELINE]: repo?.pipeline,
            [Description]: repo?.description,
            disableSubmit:
                !repo?.name ||
                !repo.description ||
                !repo?.tenant ||
                !repo?.pipeline,
        }
    }

    const [state, setState] = useState(getStateInit(repository));

    const allSettings = useSelector((settingsState) => {
        return settingsState.repositories.settings;
    });

    const tenants = useSelector((state) => {
        return state.tenants;
    });


    const loadTenants = () => {
        return tenantsService.loadTenants().subscribe((data) => {
            dispatch(setTenantsData(data));
        });
    }

    const loadRepositoryLatestDetails = () => {
        return repositoriesService.getRepository(repository.id).subscribe((updatedRepo) => {

            dispatch(updateRepositories({
                current: _.cloneDeep(updatedRepo),
                previous: repository
            }));

            if (updatedRepo.name !== repository.name ||
                updatedRepo.pipeline?.id !== repository.pipeline?.id) {
                setChangeMessage(DictionaryWithParam(Dictionary.repositoryChangeMessage,
                    {REPO_NAME: repository.name}));
            }

            repository = updatedRepo;

            setState(getStateInit(repository));

            setShowSpinner(false);
        });
    }

    (() => {
        if (didInit) { return; }
        setDidInit(true);
        const addedSubs = [];
        if (!tenants || tenants?.tableColumns?.length === 0) {
            addedSubs.push(loadTenants());
        }

        if (repository) {
            addedSubs.push(loadRepositoryLatestDetails());
        }

        if (!allSettings) {
            addedSubs.push(repositoriesService.loadSettings().subscribe((data) => {
                dispatch(setRepositoriesSettings(data));
            }));
        }
        if (addedSubs.length > 0) {
            setSubs([...subs, ...addedSubs]);
        }
    })();

    useEffect(() => {
        return () => {
            subs.forEach(s => { s.unsubscribe(); });
        };
    }, [subs]);


    const submitHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        onSubmit({
            [NAME]: state[NAME],
            [TENANT]: state[TENANT],
            [BRANCHES]: state[BRANCHES],
            [PIPELINE]: state[PIPELINE],
            [Description]: state[Description],
            id: repository?.id,
        });

    }

    const updateBranchesSettingsUponPipelineChange = (newPipeline) => {
        if (state[PIPELINE]) {
            const bricksMandatorySettings = getBricksSettings(state[PIPELINE]?.bricks, true);
            const bricksNonMandatorySettings = getBricksSettings(state[PIPELINE]?.bricks, false);
            const brickSettings = [...bricksMandatorySettings, ...bricksNonMandatorySettings];
            state[BRANCHES].forEach(branch => {
                branch.settings = branch.settings.filter(setting => {
                    return !brickSettings.find(s => s.id === setting.id);
                });
            });
            const newPipelineMandatorySettings = getBricksSettings(newPipeline?.bricks, true);
            state[BRANCHES].forEach(branch => {
                branch.settings = [...branch.settings, ...newPipelineMandatorySettings];
            });
        }
    }

    const inputChangeHandler = (inputFieldName, value) => {
        if (inputFieldName === PIPELINE) {
            updateBranchesSettingsUponPipelineChange(value);
        }

        const newState = {
            ...state,
            [inputFieldName]: value,
        }

        setState({
            ...newState,
            disableSubmit:
                !newState[NAME] ||
                !newState[Description] ||
                !newState[TENANT] ||
                !newState[BRANCHES] ||
                newState[BRANCHES]?.length === 0 ||
                !newState[PIPELINE] ||
                newState[PIPELINE]?.length === 0
        });
    }

    const tabItemSX = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    };

    const tabItemIconSX = {
        marginRight: '5px',
        width: '15px',
        height: '15px',
    };

    const tabContentSX = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%'
    };

    return (
        <Box component='form'
             onSubmit={submitHandler}
             sx={{height: '100%'}}>

            {showSpinner && <Spinner label={Dictionary.loading}/> }

            <TabMessage message={changeMessage} onClose={() => {setChangeMessage('')}}/>

            <Box sx={tabContentSX}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedTab}
                    sx={{
                        width: '200px'
                    }}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                    }}>
                    <Tab label={Dictionary.repoSettings}
                         sx={{...tabItemSX}}
                         icon={
                             (state[NAME] && state[TENANT] && state[Description]) ?
                                 <CheckCircleIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.repoSettings ? '1' : '0.3',
                                     color: Colors.acceptedColor}}/> :
                                 <DoNotDisturbOnTotalSilenceIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.repoSettings ? '1' : '0.3',
                                     color: Colors.alertColor}}/>
                         }
                         {...a11yProps(TAB.repoSettings)} />

                    <Tab label={Dictionary.pipeline}
                         sx={{...tabItemSX}}
                         icon={(state[PIPELINE]) ?
                             <CheckCircleIcon sx={{
                                 ...tabItemIconSX,
                                 opacity:  selectedTab === TAB.repoPipeline ? '1' : '0.3',
                                 color: Colors.acceptedColor}}/> :
                             <DoNotDisturbOnTotalSilenceIcon sx={{
                                 ...tabItemIconSX,
                                 opacity:  selectedTab === TAB.repoPipeline ? '1' : '0.3',
                                 color: Colors.alertColor}}/>}
                         {...a11yProps(TAB.repoPipeline)} />

                    <Tab label={Dictionary.repoBranchesSettings}
                         disabled={!Boolean(state[PIPELINE])}
                         sx={{...tabItemSX}}
                         icon={!state[PIPELINE] ?
                             <Block sx={{
                                 ...tabItemIconSX,
                                 opacity:  selectedTab === TAB.repoBranches ? '1' : '0.3',
                                 color: Colors.grayedOut}}/> :
                             <CheckCircleIcon sx={{
                                 ...tabItemIconSX,
                                 opacity: selectedTab === TAB.repoBranches ? '1' : '0.3',
                                 color: Colors.acceptedColor
                             }}/>}
                         {...a11yProps(TAB.repoBranches)} />

                    <Tab label={Dictionary.submit}
                         type='submit'
                         disabled={state.disableSubmit}
                         sx={{
                             marginTop: '50px',
                             background: state.disableSubmit ? Colors.disableBackground : Colors.mainHighlight,
                             color: state.disableSubmit ? Colors.grayedOut : `${Colors.light} !important`,
                             width: '150px',
                             boxShadow: !state.disableSubmit &&
                                 '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
                                 '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
                                 '0px 1px 5px 0px rgb(0 0 0 / 12%)',
                             borderRadius: '7px',
                         }}/>

                </Tabs>
                {selectedTab === TAB.repoSettings &&
                    <Box value={selectedTab}
                         index={TAB.repoSettings}
                         sx={{
                             width: '100%',
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                         }}>
                        <RepositorySettings id={repository?.id}
                                            description={state[Description]}
                                            name={state[NAME]}
                                            tenant={state[TENANT]}
                                            onChange={inputChangeHandler}/>
                    </Box>}

                {selectedTab === TAB.repoBranches &&
                    <Box value={selectedTab} index={TAB.repoBranches} sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <BranchSettings branches={state[BRANCHES]}
                                        pipeline={state[PIPELINE]}
                                        onChange={inputChangeHandler} />
                    </Box>}

                {selectedTab === TAB.repoPipeline &&
                    <Box value={selectedTab} index={TAB.repoPipeline} sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <RepositoryPipelineSelector pipeline={state[PIPELINE]} onChange={(data) => {
                            const settings = data.settings;
                            const ppl = data.pipeline;
                            state[BRANCHES].forEach(branch => {
                                branch.settings = _.cloneDeep(settings);
                            })
                            inputChangeHandler(PIPELINE, ppl);
                        }}/>
                    </Box>}
            </Box>
        </Box>


    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



