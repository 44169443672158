import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dictionary } from '../../../consts/dictionary';
import skyhawksLogo from '../../../assets/hawk.webp';
import geLogo from '../../../assets/ge.svg'
import { AuthApi } from '../../../consts/apiList';
import { Spinner } from '../../common/spinner/spinner';
import * as style from './style';

const theme = createTheme();

export const Login = () => {



    const [state, setState] = useState({
        disableLogin: true,
        isLoading: false,
        subs: [],
        lastFailedAttemptAlertId: undefined,
    });

    useEffect(() => {
        handleSubmit();
        return () => {
            state.subs.forEach(s => {
                s.unsubscribe();
            });
        };
    }, []);

    const handleSubmit = () => {
        setState({...state, isLoading: true});
        const redirectUrl = new URL(AuthApi.authorize);
        for (let param in AuthApi.authorizeParams) {
            redirectUrl.searchParams.append(param, AuthApi.authorizeParams[param]);
        }
        window.location.assign(redirectUrl.toString())
    };

    return (
        <Box sx={style.wrapper}>
            {state.isLoading && <Spinner label={`${Dictionary.authenticate} . . .`}/>}
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box sx={style.loginForm}>
                        <Avatar sx={style.loginFormAvatar}>
                            <img style={style.loginGEIcon}
                                 src={geLogo} alt='GEHC Logo'/>
                        </Avatar>
                        <Typography component="h1"
                                    variant="h5"
                                    textTransform='capitalize'>
                            {Dictionary.signIn}
                        </Typography>
                    </Box>
                    <Copyright sx={style.copyright}/>
                </Container>
            </ThemeProvider>
        </Box>
    );
}

function Copyright(props) {
    return (
        <Typography sx={style.copyrightWrapper}
                    variant="body2"
                    color="text.secondary"
                    align="center" {...props}>
            <img style={style.copyrightIcon} src={skyhawksLogo} alt='App Logo'/>
            <label style={style.copyrightLabel}>{Dictionary.appName}</label>
            <label style={style.copyrightLabel}>{Dictionary.version}: {Dictionary.appVersion}</label>
        </Typography>
    );
}


