import React from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";

export const PipelineSelect = ({index, error = false, selectedValue = '',
                                   label, options = [], disabled = false, onChange}) => {

    return (
        <FormControl sx={{
            marginTop: '20px',
            width: '500px',
        }}>

            <InputLabel id={`pipeline-select-name-label-${index}`}>{label}</InputLabel>
            <Select
                disabled={disabled}
                error={error}
                labelId={`pipeline-select-name-label-${index}`}
                value={selectedValue}
                onChange={(e) => { onChange(e.target.value); }}
                input={<OutlinedInput label={label}/>}
            >
                {options.map((op) => (
                    <MenuItem
                        key={op.name}
                        value={op.name}
                    >
                        {op.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
