import httpService from './http.service';
import { APIList } from '../consts/apiList';

const cloneAndAdjustRepoRequest = (repository) => {
    const _repo = {
        ...repository,
        pipelineId: repository?.pipeline?.id,
        tenantId: repository?.tenant?.id,
    };
    delete _repo.tenant;
    delete _repo.pipeline;
    return _repo;
}

class RepositoriesService {

    getRepository(repoId) {
        return httpService._get(`${APIList.repositories}/${repoId}`);
    }

    loadRepositories() {
        return httpService._get(APIList.repositories);
    }

    createRepository(repository) {
        const _repo = cloneAndAdjustRepoRequest(repository);
        return httpService._post(APIList.createRepository, { ..._repo });
    }

    deleteBulkRepositories(repositories) {
        return httpService._delete(APIList.deleteBulkRepository, {repositories});
    }

    deleteSingleRepository({name, id}) {
        return httpService._delete(APIList.deleteSingleRepository, {name, id});
    }

    updateRepository(repository = {}) {
        const _repo = cloneAndAdjustRepoRequest(repository);
        return httpService._put(`${APIList.updateRepository}/${_repo.id}`, { ..._repo });
    }

    loadSettings() {
        return httpService._get(APIList.loadRepositorySettings)
    }
}

export default new RepositoriesService();
