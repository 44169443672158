import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableColumns: [],
    list: [],
}


export const tenantsSlice = createSlice({
    name: 'tenants',
    initialState: initialState,
    reducers: {
        setTenantsData: (state, action) => {
            state.tableColumns = action.payload.tableColumns || [];
            state.list = action.payload.list || [];
        },
        updateTenant: (state, action) => {
            const updatedTenant = action.payload.current;
            const previousTenantData = action.payload.previous;
            state.list = state.list.map(tenant => {
                if (tenant.id === previousTenantData.id) {
                    return {
                        ...tenant,
                        ...updatedTenant
                    }
                }
                return tenant;
            });
        },
        reset: (state) => {
            state.loginData = {
                tableColumns: [],
                list: [],
            };
        }
    },

});

export const { setTenantsData, reset, updateTenant } = tenantsSlice.actions;

export default tenantsSlice.reducer;
