import httpService from './http.service';
import { APIList } from '../consts/apiList';

const cloneAndAdjustPipelineRequest = (pipeline) => {
    const _ppl = {
        ...pipeline,
        tenantID: pipeline?.tenant?.id,
    };
    delete _ppl.tenant;
    return _ppl;
}

class PipelinesService {
    loadPipelineSettings(pipelineId) {
        return httpService._get(`${APIList.pipelines}/${pipelineId}/settings`);
    }

    loadPipelines() {
        return httpService._get(APIList.pipelines);
    }

    createPipeline(pipeline) {
        const _ppl = cloneAndAdjustPipelineRequest(pipeline);
        return httpService._post(APIList.createPipeline, { ..._ppl });
    }

    deleteSinglePipeline({name, id}) {
        return httpService._delete(APIList.deleteSinglePipeline, {name, id});
    }

    deleteBulkPipeline(pipelines) {
        return httpService._delete(APIList.deleteBulkPipelines, {pipelines});
    }

    updatePipeline(pipeline = {}) {
        const _ppl = cloneAndAdjustPipelineRequest(pipeline);
        return httpService._put(`${APIList.updatePipeline}/${pipeline.id}`, { ..._ppl });
    }
}

export default new PipelinesService();
