import React from 'react';
import { useSelector } from 'react-redux';
import { setTeamsData, updateTeam } from '../../redux/sclices/teamsSlice';
import { Dictionary } from '../../consts/dictionary';
import TeamsService from '../../services/teams.service';
import { Page } from '../common/page/page';


export const Teams = () => {

    const teamsData = useSelector((state) => {
        return state.teams;
    });

    return (<Page data={teamsData}
                  pageDictionary={Dictionary.pages.teams}
                  loadMethod={TeamsService.loadTeams}
                  setDataDispatchMethod={setTeamsData}
                  updateDispatchMethod={updateTeam}
                  createMethod={TeamsService.createTeam}
                  deleteSingleItem={TeamsService.deleteSingleTeam}
                  editItemMethod={TeamsService.updateTeam}
                  bulkDeleteMethod={TeamsService.deleteBulkTeam}/>);
}
