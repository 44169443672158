export const Colors = {
    mainHighlight: '#1976d2',
    light: '#ffffff',
    disableBackground: '#efefefef',
    grayedOut: '#aeaeae',
    acceptedColor: '#539941',
    warining: '#ffcc00',
    alertColor: 'darkred',
    alertSoftColor: 'pink',
    dark: 'rgb(35,48,68)',
    darkHighlight: 'rgb(32,44,63)',
}
