import React, { useState } from 'react';
import { Badge, Box } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as ICONS from '@mui/icons-material';
import { Colors } from '../../../../../../consts/colors.const';

export const StepIcon = (props) => {
    const [state, setState] = useState({
        showActions: false
    });

    const setShowActionState = (status) => {
        setState({
            ...state,
            showActions: status && props.showActions
        });
    }
    const icon = ICONS[props.icon] ? ICONS[props.icon] : SettingsSuggestIcon;
    const actionSx = {
        width: '15px',
        height: '15px',
        background: Colors.light,
        color: Colors.mainHighlight,
        border: `1px solid ${Colors.mainHighlight}`,
        borderRadius: '50%',
        padding: '3px',
        marginRight: '5px',
        cursor: 'pointer',
        ':hover': {
            color: Colors.light,
            background: Colors.mainHighlight,
        }
    }
    return(
        <Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}}
               onMouseOver={() => {setShowActionState(true)}}
               onMouseLeave={() => {setShowActionState(false)}}
               badgeContent={<>
                   {state.showActions && <Box sx={{width: '50px', display: 'flex'}} >

                       {props.index > 0 &&
                           <Box sx={actionSx}
                                component={KeyboardArrowLeftIcon}
                                onClick={() => {
                                    setShowActionState(false);
                                    props.movePrev(props.index);
                                }}/>}

                       {props.index <( props.totalSize - 1) &&
                           <Box sx={actionSx}
                                component={KeyboardArrowRightIcon}
                                onClick={() => {
                                    setShowActionState(false);
                                    props.moveNext();
                                }}/>}

                       <Box sx={actionSx}
                            component={DeleteForeverIcon}
                            onClick={() => {
                                setShowActionState(false);
                                props.deleteHandler();
                            }}/>
                   </Box>}
               </>}>
            <Box onMouseOver={() => {setShowActionState(true)}} onMouseLeave={() => {setShowActionState(false)}}
                 sx={{
                     width: '50px',
                     height: '50px',
                     background: Colors.light,
                     borderRadius: '50%',
                     border: `3px solid ${Colors.mainHighlight}`,
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     zIndex: '1',
                 }}>

                <Box component={icon}
                     sx={{
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center'
                     }}>

                </Box>

            </Box>
        </Badge>
    );
}
