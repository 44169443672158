export const wrapper = {
    paddingTop: '100px',
    boxSizing: 'border-box'
}

export const loginForm = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}

export const loginFormAvatar = {
    m: 1,
    bgcolor: 'transparent'
}

export const loginGEIcon = {
    width: '90%'
}

export const submitButton = {
    marginTop: '25px'
}

export const copyright = {
    mt: 8,
    mb: 4
}

export const copyrightWrapper = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const copyrightIcon = {
    width: '25px'
}
export const copyrightLabel = {
    textTransform: 'capitalize',
    fontWeight: '500',
    marginLeft: '5px'
}
