import React from 'react';
import {Box} from "@mui/material";
import {SHSearch} from "../SHSearch/SHSearch";

export const FiltersBox = ({searchHandler}) => {

    return <Box sx={{
        background: 'white',
        width: 'calc(100% - 10px)',
        height: '90px',
        margin: '5px',
        boxShadow: '0px 0px 6px 0px black',
        boxSizing: 'border-box',
        borderRadius: '7px',
        padding: '0 20px',
        display: 'flex',
        alignItems: 'center',
    }}>
        <SHSearch searchHandler={searchHandler}/>
    </Box>
}
