import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './sclices/login.slice';
import alertReducer from './sclices/alert.slice';
import pagesReducer from './sclices/pages.slice';
import tenantsReducer from './sclices/tenantsSlice';
import pipelinesReducer from './sclices/pipelines.slice';
import bricksReducer from './sclices/bricks.slice';
import repositoriesReducer from './sclices/repositories.slice';
import teamsReducer from './sclices/teamsSlice';

export default configureStore({
    reducer: {
        teams: teamsReducer,
        alert: alertReducer,
        login: loginReducer,
        pages: pagesReducer,
        tenants: tenantsReducer,
        pipelines: pipelinesReducer,
        bricks: bricksReducer,
        repositories: repositoriesReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
