
export const homePage = {
    '--home-page-header-height': '70px',
    width: '100%',
    height: '100%',
}

export const homePageHeader = {
    height: 'var(--home-page-header-height)',
    borderBottom: '1px solid #eeeeee'
}

export const homePageBody = {
    '--home-page-menu-width': '258px',
    width: '100%',
    height: 'calc(100% - var(--home-page-header-height))',
    display: 'flex',
}

export const homePageMenu = {
    'width': 'var(--home-page-menu-width)',
    height: '100%'
}

export const homePageContent = {
    width: 'calc(100% - var(--home-page-menu-width))',
    padding: '45px',
    position: 'relative',
    minWidth: '1190px'
}
