import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setRepositoriesSettings } from '../../../redux/sclices/repositories.slice';
import { useNavigate, Route, Routes,  } from 'react-router-dom';
import repositoriesService from '../../../services/repositories.service';
import { RouteTable } from '../../../consts/routeTable';
import { Dictionary } from "../../../consts/dictionary";
import { SideMenu } from './sideMenu/sideMenu';
import { Header } from './header/header';
import { Tenants } from '../../tenants/tenants';
import { Pipelines } from '../../pipelines/pipelines';
import { Bricks } from '../../bricks/bricks';
import { Repositories } from '../../repositories/repositories';
import { Teams } from '../../teams/teams';
import { Spinner } from '../../common/spinner/spinner';
import * as style from './style';

export const Home = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [didInit, setDidInit] = useState(false);

    const selectedPageComponent = useSelector((state) => {
        return state.pages.currentPage;
    });

    const loginData = useSelector((state) => {
        return state.login.loginData;
    });

    const settings = useSelector((state) => {
        return state.repositories.settings;
    });

    (() => {
        if (didInit || settings) { return; }
        setDidInit(true);
        repositoriesService.loadSettings().subscribe((data) => {
            dispatch(setRepositoriesSettings(data));
        });
    })();

    useEffect(() => {
        if (!loginData) {
            navigate(RouteTable.login);
            return;
        }

        if (selectedPageComponent) {
            navigate(`${selectedPageComponent.route}`)
        }
        return () => {};
    }, [selectedPageComponent]);

    useEffect(() => {
        window.addEventListener('popstate', () => {
            if (window.location.pathname === '/home') {
                navigate(RouteTable.login);
            }
        });
    }, []);


    return (
        <section style={style.homePage}>
            <section style={style.homePageHeader}>
                <Header/>
            </section>
            {didInit &&
                <section style={style.homePageBody}>
                    <section style={style.homePageMenu}>
                        <SideMenu/>
                    </section>
                    <section style={style.homePageContent}>

                        <Routes>
                            <Route path={RouteTable.homeRoutes.tenants} element={<Tenants/>}/>
                            <Route path={RouteTable.homeRoutes.teams} element={<Teams/>}/>
                            <Route path={RouteTable.homeRoutes.pipelines} element={<Pipelines/>}/>
                            <Route path={RouteTable.homeRoutes.bricks} element={<Bricks/>}/>
                            <Route path={RouteTable.homeRoutes.repositories} element={<Repositories/>}/>
                        </Routes>

                    </section>
                </section>
            }

            {!didInit && <Spinner label={Dictionary.loading}/> }

        </section>
    );
}
