import React from 'react';
import { TextField } from '@mui/material';

export const SettingsText = ({value, label, onChange, error}) => {
    return <TextField label={label}
                      error={error}
                      onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                              event.stopPropagation();
                              event.preventDefault();
                          }
                      }}
                      variant="standard"
                      value={value || ''}
                      onChange={(e) => {
                          onChange(e?.target?.value);
                      }}/>
}
