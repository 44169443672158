export const Dictionary  = {
    activate: 'activate',
    addBranchOrPattern: 'add new branch / branch pattern',
    add: 'add',
    addItem: '+ add item',
    addItemsForSelection: 'add items for selection',
    addIcon: 'add icon',
    addOptionsForSelection: 'Add options for selection',
    addSettings: 'Please add settings',
    addSingleSetting: 'Add setting',
    appName: 'skyhawks',
    appVersion: '0.0.1',
    applyDefaultSettings: '+ apply default settings',
    applyDefaultPipeline: '+ apply $TENANT_NAME$ default pipeline',
    authenticate: 'authenticate',
    authenticating: 'authenticating...',
    branch: 'branch',
    branches: 'branches',
    branchName: 'branch name',
    bricks: 'bricks',
    brickName: 'Brick name',
    brickMachineName: 'Brick machine name (Skyhawk name)',
    buildType: 'build type',
    cancel: 'cancel',
    changeIcon: 'change icon',
    changePipelineWarning: 'Changing the selected pipeline might effect the branches settings',
    createSettings: 'create setting',
    currentSettings: 'current settings',
    createNewBranch: 'Create new branch',
    ctrlAddItem: 'CTRL + q',
    deactivate: 'deactivate',
    defaultValue: 'Default value',
    delete: 'delete',
    description: 'Description',
    edit: 'edit',
    editBranchName: 'Edit branch name',
    failedToGetLatestTeamDetails: 'Failed to get latest team details',
    'false': 'false',
    geHealthcare: 'GE Healthcare',
    gitLabGroupID: 'GitLab group ID',
    id: 'id',
    invalidInputDefault: 'invalid input, use only english letters, numbers or "-"',
    loading: 'loading',
    loadingRepositories: 'loading repositories',
    logout: 'logout',
    mainMenuPagesTitle: 'pages',
    mandatory: 'mandatory',
    no: 'no',
    noMatchItems: 'no items match',
    optionKey: 'Option Key',
    pipelineName: 'Pipeline name',
    pipelineNameAndTenant: 'name & tenant',
    pipelineBricks: 'bricks',
    pipeline: 'pipeline',
    repoSettings: 'repository settings',
    repoBranchesSettings: 'branches settings',
    repositoryChangeMessage: 'Repository "$REPO_NAME$" details has recently changed',
    repositoriesHeader: 'Repositories (optionally)',
    repositoryName: 'Repository name',
    repository: 'repository',
    runtime: 'runtime',
    search: 'search',
    selectBricksHint: 'Drag and Drop Bricks from the options below',
    selectIcon: 'select icon',
    selectOptions: 'select options',
    settings: 'settings',
    settingName: 'setting name',
    signIn: 'sign in',
    sso: 'sso',
    submit: 'submit',
    tenantName: 'Tenant name',
    teamName: 'Team name',
    tenantChangeMessage: 'Tenant "$TENANT_NAME$" details has recently changed',
    templates: 'templates',
    teamNameAndDescriptionChanged: 'Team "$TEAM_NAME$" description and name has recently changed to $NEW_TEAM_NAME$',
    teamDescriptionChanged: 'Team "$TEAM_NAME$" description has recently changed',
    teamNameChanged: 'Team "$TEAM_NAME$" name has recently changed to $NEW_TEAM_NAME$',
    'true': 'true',
    tryAgain: 'try again',
    version: 'version',
    yes: 'yes',
    alert: {
        loginFailure: 'failed to login',
        loadPipelinesFailure: 'failed to load Pipelines',
        pleaseTryAgain: 'Please try again',
        deletePipelineFailure: 'failed to delete Pipeline "$PIPELINE_NAME$"',
    },
    pages: {
        teams: {
            name: 'teams',
            create: 'create new team',
            reload: 'reload teams',
            loadingText: 'Loading Teams',
            loadDataFailure: 'failed to load Teams',
            updateFailure: 'failed to update team',
            createFailure: 'failed to create team "$ITEM_NAME$"',
            deleteFailure: 'failed to delete team "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ teams',
            addItemTooltip: 'add new team',
        },
        tenants: {
            name: 'tenants',
            create: 'create new tenant',
            reload: 'reload tenants',
            loadingText: 'Loading Tenants',
            loadDataFailure: 'failed to load Tenants',
            updateFailure: 'failed to update tenant',
            createFailure: 'failed to create tenant "$ITEM_NAME$"',
            deleteFailure: 'failed to delete tenant "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ tenants',
            addItemTooltip: 'add new tenant',
        },
        pipelines: {
            name: 'pipelines',
            create: 'create new pipeline',
            reload: 'reload pipelines',
            loadingText: 'Loading Pipelines',
            loadDataFailure: 'failed to load pipelines',
            updateFailure: 'failed to update pipeline',
            createFailure: 'failed to create pipeline "$ITEM_NAME$"',
            deleteFailure: 'failed to delete pipeline "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ pipelines',
            addItemTooltip: 'add new pipeline',
        },
        bricks: {
            name: 'bricks',
            create: 'create new brick',
            reload: 'reload bricks',
            loadingText: 'Loading Bricks',
            loadDataFailure: 'failed to load bricks',
            updateFailure: 'failed to update brick',
            createFailure: 'failed to create brick "$ITEM_NAME$"',
            deleteFailure: 'failed to delete brick "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ bricks',
            addItemTooltip: 'add new brick',
        },
        repositories: {
            name: 'repositories',
            create: 'create new repository',
            reload: 'reload repositories',
            loadingText: 'Loading Repositories',
            loadDataFailure: 'failed to load Repositories',
            updateFailure: 'failed to update repository',
            createFailure: 'failed to create repository "$ITEM_NAME$"',
            deleteFailure: 'failed to delete repository "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ repositories',
            addItemTooltip: 'add new repository',
        },
    }
}

export const DictionaryWithParam = (str = '', params = {}) => {
    if (!str) {
        return '';
    }
    Object.keys(params).forEach(key => {
        str = str.replace(`$${key}$`, params[key]);
    });
    return str;
}
