import httpService from './http.service';
import { APIList } from '../consts/apiList';

const cloneAndAdjustTenantRequest = (tenant) => {
    const _tenant = {
        ...tenant,
        teamId: tenant?.team?.id
    };
    delete _tenant.team;
    return _tenant;
}

class TenantsService {

    getTenant(tenantId) {
        return httpService._get(`${APIList.tenants}/${tenantId}`);
    }

    loadTenants() {
        return httpService._get(APIList.tenants);
    }

    createTenant(tenant) {
        const _tenant = cloneAndAdjustTenantRequest(tenant);
        return httpService._post(APIList.createTenant, { ..._tenant });
    }

    deleteBulkTenant(tenants) {
        return httpService._delete(APIList.deleteBulkTenants, {tenants});
    }

    deleteSingleTenant({name, id}) {
        return httpService._delete(APIList.deleteSingleTenant, {name, id});
    }

    updateTenant(tenant = {}) {
        const _tenant = cloneAndAdjustTenantRequest(tenant);
        return httpService._put(`${APIList.updateTenant}/${tenant.id}`, { ..._tenant });
    }
}

export default new TenantsService();
