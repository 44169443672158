import React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Breadcrumbs, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../../consts/colors.const';

export const InnerHeader = ({title, reload, breadcrumbs}) => {
    return(
        <>
            <Paper sx={{
                height: '75px',
                bgcolor: 'transparent',
                boxShadow: 'none',
                paddingBottom: '22px',
                display: 'flex'
            }}>
                <Box>
                    <Typography variant='h5' sx={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize'
                    }}>
                        {title}
                    </Typography>
                    {breadcrumbs &&
                        <Box sx={{ paddingTop: '15px'}}>
                            <Breadcrumbs>
                                {breadcrumbs?.items?.map((view, index) =>
                                    <Typography
                                        key={index}
                                        onClick={() => {
                                            breadcrumbs.onItemClick(index)
                                        }}
                                        sx={{
                                            cursor: index === breadcrumbs.items.length -1 ? 'default' : 'pointer',
                                            color: index === breadcrumbs.items.length -1 ? '' : Colors.mainHighlight,
                                            textTransform: 'capitalize',
                                            ":hover": {
                                                fontWeight: index === breadcrumbs.items.length -1 ? '' : 'bold'
                                            }
                                        }}>{view.title}</Typography>
                                )}
                            </Breadcrumbs>
                        </Box>
                    }
                </Box>
                {reload &&
                    <Tooltip title={reload.tooltip}>
                        <IconButton sx={{
                            marginLeft: 'auto',
                            width: '20px',
                            height: '20px',
                            ':hover': {color: Colors.mainHighlight}
                        }}
                                    onClick={reload.callback}
                                    aria-label="close"
                                    color="inherit"
                                    size="small">
                            <ReplayIcon/>
                        </IconButton>
                    </Tooltip>
                }

            </Paper>
            <Divider sx={{marginBottom: '20px'}}/>
        </>
    );
}
