import React, { useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Colors } from '../../../../../consts/colors.const';
import { Dictionary } from '../../../../../consts/dictionary';

export const SettingRow = ({settingsNameMap, setting, onDelete}) => {

    const [expanded, setExpended] = useState(false);

    const handleExpandClick = () => {
        setExpended(!expanded)
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                background: Colors.dark,
                width: '100%',
                color: Colors.grayedOut,
                padding: '5px',
                boxSizing: 'border-box',
                borderRadius: '3px',
                cursor: 'pointer'
            }} onClick={handleExpandClick}>
                {
                    expanded ? <ExpandLess/> : <ExpandMore/>
                }
                <Typography sx={{marginRight: '20px'}}>{setting.name}</Typography>
                <Typography>({settingsNameMap[setting.type]})</Typography>

                <Delete sx={{marginLeft: 'auto', ':hover': {color: Colors.mainHighlight}}}
                        onClick={(event) => {
                            event.stopPropagation();
                            onDelete()
                        }}/>

            </Box>

            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <Box sx={{padding: '5px 35px', background: Colors.grayedOut, color: Colors.dark}}>

                    <Typography sx={{textTransform: 'capitalize'}}>
                        {Dictionary.mandatory}: {setting.isMandatory ? Dictionary.yes : Dictionary.no}
                    </Typography>

                    {
                        setting.isMandatory &&
                        <Typography>{Dictionary.defaultValue}: {setting.defaultValue}</Typography>
                    }

                    {(setting.selectOptions && setting.selectOptions?.length) && <Box>
                        <Typography>{Dictionary.selectOptions}:</Typography>
                        {
                            setting.selectOptions.map((item, index) =>
                                <li style={{
                                    marginLeft: '15px',
                                    color: item === setting.defaultValue ? Colors.mainHighlight : Colors.dark
                                }} key={index}>{item}</li>
                            )
                        }
                    </Box>}
                </Box>

            </Collapse>
        </Box>);
}
