import React from 'react';
import { Box, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { BricksConnector } from './bricksConnector/bricksConnector';
import { StepIcon } from './stepIcon/stepIcon';
import { Dictionary } from '../../../../../consts/dictionary';
import { Colors } from '../../../../../consts/colors.const';
import { BRICKS } from '../../pipelineEditor';



export const PipelineBricks = ({bricks, onChange}) => {


    const allowDrop = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const brick = JSON.parse(event.dataTransfer.getData(BRICKS));
        addBrick(brick);
    }

    const addBrick = (brick) => {
        if (!bricks.find(b => b.id === brick.id)) {
            onChange([...bricks, brick]);
        }
    }

    const deleteBrick = (index) => {
        bricks.splice(index, 1);
        onChange(bricks);
    }

    const moveBrickForward = (index) => {
        const brick = bricks[index];
        bricks[index] = bricks[index + 1];
        bricks[index + 1 ] = brick;
        onChange(bricks);
    }

    const moveBrickBackward = (index) => {
        const brick = bricks[index];
        bricks[index] = bricks[index - 1];
        bricks[index - 1 ] = brick;
        onChange(bricks);
    }

    return (<>
        <Box sx={{
            width: '90%',
            height: '100%',
            minHeight: '150px',
            border: `2px dashed ${Colors.mainHighlight}`,
            boxSizing: 'border-box',
            borderRadius: '7px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowX: 'scroll',
            position: 'relative'
        }}
             onDrop={handleDrop}
             onDragOver={allowDrop}>
            {bricks?.length > 0 &&
            <Stack sx={{width: '100%'}} spacing={4}>
                <Stepper alternativeLabel activeStep={1} connector={<BricksConnector/>}>
                    {bricks.map((brick, index) =>
                        <Step key={index}>
                            <StepLabel StepIconComponent={StepIcon}
                                       StepIconProps={{
                                           showActions: Boolean(onChange),
                                           icon: brick.icon,
                                           deleteHandler: () => {deleteBrick(index)},
                                           moveNext: () => {moveBrickForward(index)},
                                           movePrev: () => {moveBrickBackward(index)},
                                           index,
                                           totalSize: bricks.length,
                                       }}>{brick.name}</StepLabel>
                        </Step>)}
                </Stepper>
            </Stack>}
            {bricks?.length === 0 &&
                <Typography variant='h6' sx={{
                    width: '100%',
                    color: Colors.mainHighlight,
                    position: 'absolute',
                    textAlign: 'center',
                    textDecoration: 'underline'
                }}>
                    {Dictionary.selectBricksHint}
                </Typography>}
        </Box>
    </>)
}


