export const RouteTable = {
    login: '/',
    home: '/home',
    homeRoutes: {
        teams: 'teams',
        tenants: 'tenants',
        pipelines: 'pipelines',
        bricks: 'bricks',
        repositories: 'repositories'
    },
    auth: 'auth-callback',
}
