import React from 'react';
import { useSelector } from 'react-redux';
import { setPipelinesData, updatePipeline } from '../../redux/sclices/pipelines.slice';
import { Dictionary } from '../../consts/dictionary';
import PipelinesService from '../../services/pipelines.service';
import { Page } from '../common/page/page';

export const Pipelines = () => {

    const pipelinesData = useSelector((state) => {
        return state.pipelines;
    });

    return (
       <Page data={pipelinesData}
             pageDictionary={Dictionary.pages.pipelines}
             loadMethod={PipelinesService.loadPipelines}
             setDataDispatchMethod={setPipelinesData}
             updateDispatchMethod={updatePipeline}
             createMethod={PipelinesService.createPipeline}
             deleteSingleItem={PipelinesService.deleteSinglePipeline}
             editItemMethod={PipelinesService.updatePipeline}
             bulkDeleteMethod={PipelinesService.deleteBulkPipeline}/>
    );
}
