import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoginData } from '../../../redux/sclices/login.slice';
import { setPages } from '../../../redux/sclices/pages.slice';
import shIcon from '../../../assets/hawk.webp'
import { UserRole, UserRoleGroupId } from '../../../enums/userRole.enum';
import { RouteTable } from '../../../consts/routeTable';
import { Dictionary } from '../../../consts/dictionary';
import AuthenticationService from '../../../services/authentication.service';
import { Spinner } from '../../common/spinner/spinner';
import * as styles from './style';

export const Authentication = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const authJWTRequest = authenticate();

        return () => {
            authJWTRequest?.unsubscribe();
        }
    }, []);

    const authenticate = () => {
        const code = new URLSearchParams(window.location.search).get('code');
        if (!code) {
            navigate(RouteTable.login);
        }
        return AuthenticationService.authJWTRequest(code)
            .subscribe((data) => {
                if (!data) {
                    navigate(RouteTable.login);
                    return;
                }
                const userRole = getUserRole(data.parsed.id_token['custom:user_groups']);
                dispatch(setPages(userRole))
                dispatch(setLoginData({
                    ...data,
                    username: data.parsed.id_token['custom:sso_id'],
                    expireTime: data.tokens.expires_in * 1000 + Date.now(),
                }));
                navigate(RouteTable.home);
            });
    }

    const getUserRole = (groups = '[]') => {
        const _groups = groups.substring(1, groups.length - 1).split(', ');
        return  _groups.includes(UserRoleGroupId.master) ? UserRole.master :
                _groups.includes(UserRoleGroupId.devOps) ? UserRole.devOps :
                _groups.includes(UserRoleGroupId.dev) ? UserRole.dev :
                UserRole.unAuthorized;
    }

    return(
        <Paper sx={styles.wrapper}>
            <img src={shIcon} alt='logo'
                 style={styles.logo}/>
                <Spinner label={Dictionary.authenticating}/>
        </Paper>
    )
}
