export const UserRole = {
    dev: 30,
    devOps: 20,
    master: 10,
    unAuthorized: 1000,
}

export const UserRoleGroupId = {
    master: 'g02892073',
    devOps: 'g02892075',
    dev: 'g02892076',
}
