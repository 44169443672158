export const wrapper = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px',
    boxSizing: 'border-box',
}

export const logo = {
    width: '700px'
}
