import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableColumns: [],
    list: [],
    loaded: false,
}


export const teamsSlice = createSlice({
    name: 'teams',
    initialState: initialState,
    reducers: {
        setTeamsData: (state, action) => {
            state.tableColumns = action.payload.tableColumns || [];
            state.list = action.payload.list || [];
            state.loaded = true;
        },
        updateTeam: (state, action) => {
            const updatedTeam = action.payload.current;
            const previousTeamData = action.payload.previous;
            state.list = state.list.map(team => {
                if (team.id === previousTeamData.id) {
                    return {
                        ...team,
                        ...updatedTeam
                    }
                }
                return team;
            });
        },
        reset: (state) => {
            state.loginData = {
                tableColumns: [],
                list: [],
            };
        }
    },

});

export const { setTeamsData, reset, updateTeam } = teamsSlice.actions;

export default teamsSlice.reducer;
