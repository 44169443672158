import React from 'react';
import { useSelector } from 'react-redux';
import { setRepositoriesData, updateRepositories } from '../../redux/sclices/repositories.slice';
import { Dictionary } from '../../consts/dictionary';
import RepositoriesService from '../../services/repositories.service';
import { Page } from '../common/page/page';


export const Repositories = () => {

    const repositoriesData = useSelector((state) => {
        return state.repositories;
    });

    return (<Page data={repositoriesData}
                  pageDictionary={Dictionary.pages.repositories}
                  loadMethod={RepositoriesService.loadRepositories}
                  setDataDispatchMethod={setRepositoriesData}
                  updateDispatchMethod={updateRepositories}
                  createMethod={RepositoriesService.createRepository}
                  deleteSingleItem={RepositoriesService.deleteSingleRepository}
                  editItemMethod={RepositoriesService.updateRepository}
                  bulkDeleteMethod={RepositoriesService.deleteBulkRepositories}/>);
}
