import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Box, Divider } from '@mui/material';
import { Dictionary } from '../../../../consts/dictionary';
import { BUILD_TYPE, NAME, PIPELINE_ID, RUNTIME, TENANT, Description } from '../pipelineEditor';
import tenantsService from '../../../../services/tenants.service';
import { Spinner } from '../../../common/spinner/spinner';
import { setTenantsData } from "../../../../redux/sclices/tenantsSlice";
import { TextFieldWithRegexValidation } from '../../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';
import { PipelineSelect } from './pipelineSelect/pipelineSelect';

export const PipelineMeta = ({tenant, name, description, runtime = '', id = '', buildType = '', onChange}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const dispatch = useDispatch();

    const settings = useSelector((state) => {
        return state.repositories.settings?.pipeline;
    });

    const pipelinesTenants = useSelector((state) => {
        return state.tenants?.list
    });

    const [disableEditId, setDisableId] = useState(false);

    useEffect(() => {
        setDisableId(Boolean(id));
        if (!pipelinesTenants || pipelinesTenants.length === 0) {
            setShowSpinner(true);
            tenantsService.loadTenants().subscribe(data => {
                dispatch(setTenantsData(data))
                setShowSpinner(false);
            }, () => {
                setShowSpinner(false);
            });
        }
    }, [])

    const getRealtimeOptions = (buildType) => {
        const buildTypeObj = settings?.find(element => element.name === buildType);
        return buildTypeObj ? buildTypeObj.realTimeOptions.map(op => {
            return {
                name: op
            }
        }) : []
    }

    return(<>
        {showSpinner && <Spinner label={Dictionary.loading}/>}
        <Box sx={{
            display: 'flex',
            width: '100%',
            padding: '0 40px',
            boxSizing: 'border-box',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <TextField required={true}
                           value={name}
                           onInput={(e) => {
                               onChange(NAME, e.target.value);
                           }}
                           name={NAME}
                           sx={{
                               marginTop: '20px',
                               width: '500px'
                           }}
                           label={Dictionary.pipelineName}
                           variant="filled"/>
                <Box sx={{
                    marginTop: '20px',
                    width: '500px'
                }}>
                    <TextFieldWithRegexValidation value={id}
                                                  disabled={disableEditId}
                                                  label={Dictionary.id}
                                                  name={PIPELINE_ID}
                                                  onChange={(value) => {
                                                      onChange(PIPELINE_ID, value);
                                                  }}
                                                  onError={(value) => {
                                                      onChange(PIPELINE_ID, value);
                                                  }}/>

                </Box>

                <PipelineSelect label={Dictionary.tenantName}
                                error={!tenant?.name}
                                index={'tenant'}
                                selectedValue={tenant?.name || ''}
                                options={pipelinesTenants}
                                onChange={(value) => {
                                    const tenantInstance = pipelinesTenants.find(t => t.name === value);
                                    onChange(TENANT, tenantInstance);
                                }}/>

                <PipelineSelect label={Dictionary.buildType}
                                error={!buildType}
                                index={'build-type'}
                                selectedValue={buildType}
                                options={settings}
                                onChange={(value) => {
                                    onChange(BUILD_TYPE, value);
                                }}/>

                <PipelineSelect label={Dictionary.runtime}
                                error={!runtime}
                                disabled={!buildType}
                                index={'runtime'}
                                selectedValue={runtime}
                                options={getRealtimeOptions(buildType)}
                                onChange={(value) => {
                                    onChange(RUNTIME, value);
                                }}/>
            </Box>
            <Divider orientation="vertical"
                     flexItem
                     sx={{margin: '0 30px'}}/>

            <Box sx={{paddingTop: '20px', width: '100%'}}>
                <TextField required={true}
                           value={description}
                           onInput={(e) => {
                               onChange(Description, e.target.value);
                           }}
                           name={Description}
                           sx={{
                               paddingBottom: '10px',
                               width: '100%',
                           }}
                           label={Dictionary.description}
                           multiline
                           rows={14}
                           variant="filled"
                />
            </Box>
        </Box>
    </>);
}
