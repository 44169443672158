import React, { useState } from 'react';
import * as ICONS from '@mui/icons-material';
import { Button, Typography, Box, Pagination, Tooltip } from '@mui/material';
import { Colors } from '../../../../consts/colors.const';
import { Dictionary } from '../../../../consts/dictionary';
import { SHSearch } from '../../../common/SHSearch/SHSearch';

const PAGE_SIZE = 192;

export const IconSelector = ({onClose, onSelect}) => {

    const getIcons = (filter = '') => {
        return Object.keys(ICONS)
            .filter(icon => icon.indexOf('Rounded') === -1)
            .filter(icon => icon.indexOf('Sharp') === -1)
            .filter(icon => icon.indexOf('TwoTone') === -1)
            .filter(icon => icon.indexOf('Outlined') === -1)
            .filter(icon => icon.toLowerCase().indexOf(filter.toLowerCase()) > -1);
    }

    const [state, setState] = useState({
        allIcons: getIcons(),
        filteredIcons: getIcons(),
        totalIconsPages: Math.ceil(getIcons().length / PAGE_SIZE),
        currentPage: 1,
    });

    const handlePageChanged = (event, page) => {
        if (page !== state.currentPage) {
            setState({
                ...state,
                currentPage: page
            });
        }
    }

    const handleSearchChange = (text) => {
        const filteredIcons = getIcons(text);
        setState({
            ...state,
            currentPage: 1,
            filteredIcons,
            totalIconsPages: Math.ceil(filteredIcons.length / PAGE_SIZE),
        });
    }

    return (
        <Box sx={{
            width: '500px',
            height: '400px',
            background: 'white'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
                boxSizing: 'border-box',
                background: Colors.dark,
                color: Colors.grayedOut,
                height: '50px'}}>
                <Typography variant="h6"
                            component="h2"
                            sx={{textTransform: 'capitalize'}}>
                    {Dictionary.selectIcon}
                </Typography>
                <Box sx={{
                    background: Colors.grayedOut,
                    borderRadius: '15px',
                    width: '150px',
                    height: '35px',
                    display: 'flex',
                    alignItems: 'center'}}>
                    <SHSearch searchHandler={handleSearchChange} addSearchLabel={false}/>
                </Box>
            </Box>
            <Box sx={{
                height: 'calc(100% - 90px)',
                width: '100%',
                overflowY: 'scroll',
                padding: '10px',
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}>
                {state.filteredIcons.slice((state.currentPage - 1) * PAGE_SIZE, state.currentPage * PAGE_SIZE)
                    .map((icon, index) =>
                        <Tooltip title={icon}
                                 key={`tooltip_${index}`}>
                            <Box component={ICONS[icon]} key={index}
                                 sx={{
                                     width: '30px',
                                     height: '30px',
                                     margin: '3px',
                                     cursor: 'pointer',
                                     ':hover': {color: Colors.mainHighlight}
                                 }}
                                 onClick={() => {
                                     onSelect(icon);
                                 }}
                            />
                        </Tooltip>
                    )}
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'baseline',
                padding: '5px 10px 10px 0',
                boxSizing: 'border-box',
                justifyContent: 'space-around'
            }}>
                <Pagination count={state.totalIconsPages}
                            page={state.currentPage}
                            onChange={handlePageChanged}/>
                <Button variant='outlined'
                        sx={{
                            fontSize: '10px',
                            height: '30px',
                            marginLeft: 'auto'
                        }}
                        onClick={onClose}>
                    {Dictionary.cancel}
                </Button>
            </Box>
        </Box>
    );
}
