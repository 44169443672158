export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
}


export const getBricksSettings = (bricks = [], mandatory = true) => {
    return  bricks.reduce((total, brick) => {
        const filteredSettings = brick.settings?.filter(set => mandatory ? set.isMandatory : !set.isMandatory)
            .map(set => {
                return {
                    id: set.id,
                    name: set.name,
                    type: Number(set.type),
                    isMandatory: set.isMandatory,
                    value: set.defaultValue,
                    selectOptions: set.selectOptions
                }
            }) || [];
        return [
            ...total,
            ...filteredSettings];
    }, []);
}
