export const sideMenu = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(35, 48, 68)',
    color: 'white',
    '--gray-color': 'rgba(193,193,193,.4)'
}

export const sideMenuHeader = {
    height: '64px',
    padding: '8px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const sideMenuAppLogo = {
    width: '50px'
}

export const appName = {
    textTransform: 'capitalize',
    fontWeight: '500'
}

export const sideMenuList = {
    height: 'calc(100% - 64px)',
    '--list-item-padding': '12px 28px 12px 32px',
    ':hover': {
        overflowY: 'scroll'
    }
}

export const sideMenuListHeader = {
    padding: 'var(--list-item-padding)',
    color: 'var(--gray-color)',
    textTransform: 'uppercase',
    fontWeight: '500 !important',
    fontSize: '0.696429rem !important',
}

export const sideMenuListItem = {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 'var(--list-item-padding)',
    textTransform: 'capitalize',
    cursor: 'pointer',
    ':hover': {
        background: 'rgba(0, 0, 0, 0.08)',
    }
}

export const sideMenuListItemIcon = {
    color: 'var(--gray-color)',
    width: '20px',
    height: '20px',
}

export const sideMenuListItemName = {
    color: 'rgb(238, 238, 238)',
    fontSize: '13px',
    padding: '0px 16px'
}

export const sideMenuListItemSelected = {
    background: 'rgb(30, 41, 58)'
}
