import React from 'react';
import { Box, Checkbox, Paper, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Colors } from '../../../../consts/colors.const';
import { CHECKBOX, COLLAPSE } from '../SHTable';
import './SHTableHead.css';

export const SHTableHead = ({columns, rows, checkedRows, onSelectAll, bulkActions, showBulkActions, addItem}) => {
    return(
        <TableHead  sx={{
            position: 'relative',
            boxShadow: !!showBulkActions ? '0 0 13px 0px rgb(35, 48, 68)' : '0 0 5px 0px rgb(35, 48, 68)',
            background: !!showBulkActions ? 'rgb(35, 48, 68)' : ''
        }}>
            <TableRow>
                {columns.map((col, index) => {
                    switch (col.id) {
                        case COLLAPSE:
                            return <TableCell key={index} width='30px' sx={{color: !!showBulkActions ? 'white' : ''}}/>
                        case CHECKBOX:
                            return <TableCell key={index} width='30px'>
                                <Checkbox checked={(checkedRows.length === rows.length) && rows.length > 0}
                                          onClick={onSelectAll}
                                          className={!!showBulkActions ? 'bulk-checkbox-selected' : ''}
                                          indeterminate={checkedRows.length > 0 && checkedRows.length !== rows.length}/>
                            </TableCell>
                        default:
                            return <TableCell key={index}
                                              sx={{
                                                  textTransform: 'uppercase',
                                                  fontWeight: 'bold',
                                                  color: !!showBulkActions ? 'white' : ''}}>
                                {col.label}
                            </TableCell>
                    }
                })}
                {showBulkActions &&
                    <td className='bulk-actions-td-wrapper'>
                        <Box sx={{
                            position: 'absolute',
                            background: 'transparent',
                            top: '0',
                            right: '0',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 'fit-content',
                            height: '100%',
                            paddingRight: '20px',
                            }}>
                            <Typography variant='body1' sx={{color: '#eeeeee', fontWeight: 'bold', letterSpacing: '3px'}}>
                            ({checkedRows.length})
                        </Typography>
                            {bulkActions.map((bulkAction, index) =>
                            <Paper key={index}
                                   sx={{
                                       color: '#eeeeee',
                                       background: 'transparent',
                                       boxShadow: 'none',
                                       cursor: 'pointer',
                                       paddingRight: '20px',
                                       ":hover": {
                                           color: 'lightgray'
                                       }
                                   }} onClick={bulkAction.callback}>
                                <Tooltip title={bulkAction.name}>
                                    {bulkAction.icon}
                                </Tooltip>
                            </Paper>
                        )}

                        </Box>
                    </td>
                }
                {(!showBulkActions && addItem) &&
                    <td>
                        <Tooltip title={addItem?.tooltip}>
                            <AddCircleOutlineIcon sx={{
                                ':hover': {color: Colors.mainHighlight},
                                width: '30px',
                                height: '30px',
                                position: 'absolute',
                                right: '28px',
                                bottom: '15px',
                                cursor: 'pointer'}}
                                                  onClick={addItem.callback}/>
                        </Tooltip>
                    </td>}
            </TableRow>
        </TableHead>
    );
}
