import {Colors} from "../../../../consts/colors.const";

export const headerWrapper = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    boxSizing: 'border-box',
    bgcolor: Colors.dark,
    color: Colors.light,
    borderRadius: '0'
}

export const headerAlignment = {
    display: 'flex',
    alignItems: 'flex-end'
}

export const headerGEName = {
    marginLeft: '10px',
    fontSize: '12px'
}

export const headerRight = {
    marginLeft: 'auto'
}

export const headerAction = {
    width: '30px',
    height: '30px',
    marginLeft: '20px',
}

const userAvatarSize = '27px';

export const userAvatar = {
    marginLeft: 'auto',
    width: userAvatarSize,
    height: userAvatarSize,
    cursor: 'pointer'
}

export const menu = {
    marginTop: '22px',
}

export const menuUserName = {
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    cursor: 'default',
    bgcolor: Colors.dark,
    color: 'gray',
    fontSize: '16px',
    boxSizing: 'border-box',
    textTransform: 'capitalize',
}

export const menuItem = {
    ':hover': {
        background: Colors.dark,
        color: Colors.light
    },
    fontSize: '15px',
    textTransform: 'capitalize'
}
