import React from 'react';
import {Skeleton} from "@mui/material";
import {Spinner} from "./spinner/spinner";

export const SHSkeletonLoadingPage = ({label = ''}) => {

    return <>
        <Spinner label={label}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '100px', marginBottom: '75px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '20px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '20px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '20px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '20px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '10px'}}/>
        <Skeleton animation='wave' variant='rounded' sx={{width: '100%', height: '50px', marginBottom: '10px'}}/>
    </>

}
