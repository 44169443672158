import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { ListAltOutlined } from '@mui/icons-material';
import { SettingsField } from '../../../../../enums/settingsField.enum';
import { Colors } from '../../../../../consts/colors.const';
import { Dictionary } from '../../../../../consts/dictionary';
import { SelectValuesModal } from '../selectValuesModal/selectValuesModal';

export const OptionSelector = ({options, selectedType, error, onChange, selectOptions, onOptionEditChange}) => {

    const [openSelectValuesModal, setOpenSelectValuesModal] = useState(false);

    return <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
        <FormControl variant="outlined"
                     sx={{
                         width: '200px',
                         marginTop: '50px'
                     }}>
            <Select variant={'standard'}
                    value={options.find(p => p.value.toString() === selectedType.toString())?.name || ''}
                    onChange={(e) => {
                        const val = e.target.value
                        onChange(options.find(op => op.name === val)?.value);
                    }}
                    error={Boolean(error)}>

                {options.map((op, key) =>
                    <MenuItem
                        key={key}
                        value={op.name}>
                        {op.name}
                    </MenuItem>
                )}

            </Select>
        </FormControl>

        <Box width={'30px'} sx={{marginLeft: '20px'}}>
            {selectedType === SettingsField.select &&
                <Tooltip title={Dictionary.addItemsForSelection} placement="top">
                    <ListAltOutlined
                        onClick={() => {
                            setOpenSelectValuesModal(true);
                        }}
                        sx={{
                            color: selectOptions?.length ? Colors.mainHighlight : Colors.alertColor,
                            cursor: 'pointer'
                        }}/>
                </Tooltip>
            }
        </Box>
        {openSelectValuesModal &&
            <SelectValuesModal open={openSelectValuesModal}
                               currentOptions={selectOptions}
                               onClose={(list) => {
                                   setOpenSelectValuesModal(false);
                                   onOptionEditChange(list);
                               }}/>
        }
    </Box>
}
