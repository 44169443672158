import React, {useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setTeamsData } from "../../../../redux/sclices/teamsSlice";
import { Colors } from '../../../../consts/colors.const';
import { Dictionary } from '../../../../consts/dictionary';
import { GitLabGroupId, TEAM, TenantName, Description } from '../tenantsEditor';
import teamsService from "../../../../services/teams.service";
import { TextFieldWithRegexValidation } from '../../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';

export const TenantMeta = ({submitHandler, inputChangeHandler, tenantName = '',
                            gitLabGroupId = '', disableSubmit, selectedTeam, disableId = false, description}) => {

    const dispatch = useDispatch();

    const [didInitTeams, setDidInitTeams] = useState(false);

    const teamsData = useSelector((state) => {
        return state.teams.loaded ? state.teams?.list : undefined;
    });

    (() => {
        if (didInitTeams || teamsData) { return; }
        setDidInitTeams(true);
        teamsService.loadTeams().subscribe((data) => {
            dispatch(setTeamsData(data));
        });
    })();

    return (
        <Box component='form'
             onSubmit={submitHandler}
             sx={{display: 'flex', flexDirection: 'column'}}>
            <TextField required={true}
                       value={tenantName}
                       onInput={(e) => {
                           inputChangeHandler(TenantName, e.target.value);
                       }}
                       name={TenantName}
                       sx={{
                           paddingBottom: '10px',
                           maxWidth: '500px'
                       }}
                       label={Dictionary.tenantName}
                       variant="filled" />
            <TextFieldWithRegexValidation value={gitLabGroupId}
                                          disabled={disableId}
                                          onChange={(value) => {
                                              inputChangeHandler(GitLabGroupId, value);
                                          }}
                                          onError={(value) => {
                                              inputChangeHandler(GitLabGroupId, value);
                                          }}
                                          name={GitLabGroupId}
                                          label={Dictionary.gitLabGroupID}/>

            <TextField required={true}
                       value={description}
                       onInput={(e) => {
                           inputChangeHandler(Description, e.target.value);
                       }}
                       name={Description}
                       sx={{
                           paddingBottom: '10px',
                           maxWidth: '500px',
                           marginTop: '20px'
                       }}
                       label={Dictionary.description}
                       multiline
                       rows={4}
                       variant="filled"
            />

            {teamsData &&
                <FormControl sx={{
                    marginTop: '20px',
                    width: '500px',
                }}>

                    <InputLabel id="tenant-meta-team-name">{Dictionary.teamName}</InputLabel>
                    <Select
                        labelId="tenant-meta-team-name"
                        id="demo-multiple-name"
                        value={selectedTeam?.name || ''}
                        onChange={(e) => {
                            const value =  e.target.value;
                            const teamInstance = teamsData.find(t => t.name === value);
                            inputChangeHandler(TEAM, teamInstance);
                        }}
                        input={<OutlinedInput label={Dictionary.teamName}/>}

                    >
                        {teamsData?.map((teamOption) => (
                            <MenuItem
                                key={teamOption.name}
                                value={teamOption.name}
                            >
                                {teamOption.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                    mt: 3,
                    mb: 2,
                    width: '500px',
                    background: disableSubmit ? Colors.disableBackground : '',
                    color: disableSubmit ? Colors.grayedOut : '',
                    ':hover': {
                        background: disableSubmit ? Colors.disableBackground : '',
                    }
                }}>
                {Dictionary.submit}
            </Button>
        </Box>
    );
}
