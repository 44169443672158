import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import './spinner.css';

export const Spinner = ({label, size}) => {
    return (
        <Backdrop className='spinner-wrapper'
                  sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                      opacity: '0.1',
                      background: 'rgba(0,0,0,0.3)'
                  }}
                  open={true}>
            <CircularProgress className={`app-spinner-circle app-spinner-size-${size}`}/>
            {label && <label className='spinner-label'>{label}</label>}
        </Backdrop>
    )
}
