import { catchError, map } from 'rxjs';
import httpService from './http.service';
import {AuthApi, AuthLogoutApi, BaseCognitoAPI, CLIENT_ID} from "../consts/apiList";
import { parseJwt } from "../consts/static.methods.const";

class AuthenticationService {

    logout(refreshToken) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        };

        const params =
            `Host=${BaseCognitoAPI}`+
            '&Accept=application/json'+
            `&client_id=${CLIENT_ID}`+
            `&token=${refreshToken}`+
            '&Content-Type=application/x-www-form-urlencoded'


        return httpService._post(AuthLogoutApi, params, headers);
    }

    authJWTRequest(code) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        const params =
        'grant_type=authorization_code'+
        `&code=${code}` +
        `&client_id=${AuthApi.authorizeParams.client_id}` +
        `&redirect_uri=${AuthApi.authorizeParams.redirect_uri}`;

        return httpService._post(AuthApi.token,
            params,
            headers).pipe(map((data) => {
            const id_token = parseJwt(data?.id_token);
            const access_token = parseJwt(data.access_token);
            return {
                tokens: data,
                parsed: {
                    id_token,
                    access_token
                }
            };
        }, catchError((error) => {
            return error
        })));
    }

    refresh(refresh_token) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        const params =
            'grant_type=refresh_token'+
            `&client_id=${AuthApi.authorizeParams.client_id}` +
            `&redirect_uri=${AuthApi.authorizeParams.redirect_uri}`+
            `&refresh_token=${refresh_token}`

        return httpService._post(AuthApi.token,
            params,
            headers).pipe(map((data) => {
            const id_token = parseJwt(data?.id_token);
            const access_token = parseJwt(data.access_token);
            return {
                tokens: data,
                parsed: {
                    id_token,
                    access_token
                }
            };
        }, catchError((error) => {
            return error
        })));
    }
}

export default new AuthenticationService();
