import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alerts: [],
    current: undefined,
    counter: 0,
};

export const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        addAlert: (state, action) => {
            if (!state.current) {
                state.current = action.payload;
            } else {
                state.alerts.push(action.payload);
                state.counter = state.alerts.length;
            }
        },
        getNextAlert: (state) => {
            state.current = state.alerts.shift();
            state.counter = state.alerts.length;
        },
        removeAlert: (state, action) => {
            const id = action.payload;
            if (state.current?.id === id) {
                state.current = state.alerts.shift();
            } else {
                state.alerts = state.alerts.filter(a => a.id !== id);
            }
            state.counter = state.alerts.length;
        },
        reset: (state) => {
            state.alerts = [];
            state.current = undefined;
            state.counter = 0;
        }
    }
});

export const { addAlert, getNextAlert, removeAlert, reset } = alertSlice.actions;

export default alertSlice.reducer;
