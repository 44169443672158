import React from 'react';
import { useSelector } from 'react-redux';
import { Select, FormControl, InputLabel, MenuItem, OutlinedInput, TextField, Box} from '@mui/material';
import { Dictionary } from '../../../../consts/dictionary';
import { NAME, TENANT, Description } from '../repositoriesEditor'
import { TextFieldWithRegexValidation } from '../../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';

export const RepositorySettings = ({id, name, description, tenant, onChange}) => {

    const tenants = useSelector((state) => {
        return state.tenants;
    });

    const handleTenantSelection = (selectedTenant) => {
        onChange(TENANT, selectedTenant);
    }

    return(<>
        {id &&
            <Box sx={{marginTop: '50px', width: '500px',}}>
                <TextFieldWithRegexValidation value={id?.toString()}
                                              disabled={true}
                                              label={Dictionary.id}
                                              name={id?.toString()}/>
            </Box>
        }

        {(tenants && tenants.tableColumns.length > 0) &&
            <FormControl variant="outlined"
                         sx={{
                             marginTop: '20px',
                             width: '500px',
                         }}>
                <InputLabel id="demo-multiple-name-label">{Dictionary.tenantName}</InputLabel>
                <Select
                    required={true}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={tenants.list.find(t => t.id === tenant?.id) || ''}
                    input={<OutlinedInput label={Dictionary.tenantName}/>}
                    onChange={(e) => {
                        handleTenantSelection(e?.target?.value);
                    }}
                >
                    {tenants.list.map((t) => (
                        <MenuItem
                            key={t.name}
                            value={t}>
                            {t.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        }

        <FormControl sx={{
            marginTop: '20px',
            width: '500px',
        }}>

            <TextField required={true}
                       value={name}
                       onInput={(e) => {
                           onChange(NAME, e.target.value);
                       }}
                       name={NAME}
                       sx={{
                           width: '500px'
                       }}
                       label={Dictionary.repositoryName}
                       variant="filled"/>
        </FormControl>

        <TextField required={true}
                   value={description}
                   onInput={(e) => {
                       onChange(Description, e.target.value);
                   }}
                   name={Description}
                   sx={{
                       marginTop: '20px',
                       width: '500px',
                   }}
                   label={Dictionary.description}
                   multiline
                   rows={4}
                   variant="filled"
        />
    </>);
}
