import React from 'react';
import { useSelector } from 'react-redux';
import { setTenantsData, updateTenant } from '../../redux/sclices/tenantsSlice';
import { Dictionary } from '../../consts/dictionary';
import TenantsService from '../../services/tenants.service';
import { Page } from '../common/page/page';


export const Tenants = () => {

    const tenantsData = useSelector((state) => {
        return state.tenants;
    });

    return (<Page data={tenantsData}
                  pageDictionary={Dictionary.pages.tenants}
                  loadMethod={TenantsService.loadTenants}
                  setDataDispatchMethod={setTenantsData}
                  updateDispatchMethod={updateTenant}
                  createMethod={TenantsService.createTenant}
                  deleteSingleItem={TenantsService.deleteSingleTenant}
                  editItemMethod={TenantsService.updateTenant}
                  bulkDeleteMethod={TenantsService.deleteBulkTenant}/>);
}
