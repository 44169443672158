import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableColumns: [],
    list: [],
}


export const bricksSlice = createSlice({
    name: 'bricks',
    initialState: initialState,
    reducers: {
        setBricksData: (state, action) => {
            state.tableColumns = action.payload.tableColumns || [];
            state.list = action.payload.list?.map(item => {
                return {
                    ...item,
                    check: !!item.check
                }
            }) || [];
        },
        updateBricks: (state, action) => {
            const updatedPipeline = action.payload.current;
            const previousPipelineData = action.payload.previous;
            state.list = state.list.map(pipeline => {
                if (pipeline.id === previousPipelineData.id) {
                    return {
                        ...pipeline,
                        ...updatedPipeline
                    }
                }
                return pipeline;
            });
        },
        reset: (state) => {
            state.loginData = {
                tableColumns: [],
                list: [],
            };
        }
    },

});

export const { setBricksData, updateBricks, reset } = bricksSlice.actions;

export default bricksSlice.reducer;
