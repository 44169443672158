import React from 'react';
import { Box, Collapse, TableCell, TableRow, Typography } from '@mui/material';
import * as ICONS from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { EPage } from '../../../../../enums/pages.enum';
import { Colors } from '../../../../../consts/colors.const';
import { Dictionary } from '../../../../../consts/dictionary';

export const SHTableRowExpand = ({ open, data }) => {

    const selectedPage = useSelector((state) => {
        return state.pages?.currentPage;
    });

    return (
        <TableRow>
            <TableCell style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: open ? '1px solid lightgray' : 0,
                background: open ? Colors.dark : Colors.light,
                color: Colors.light
            }}
                       colSpan={60}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    {
                        (selectedPage?.id === EPage.teams ||
                            selectedPage?.id === EPage.bricks)&&
                        <Box sx={{padding: '5px 35px'}}>
                            <Box sx={{fontSize: '20px', textDecoration: 'underline', color: Colors.grayedOut}}>
                                {Dictionary.description}:
                            </Box>
                            <Box sx={{
                                marginLeft: '20px',
                                fontSize: '15px',
                                whiteSpace: 'break-spaces',
                                margin: '5px 15px'
                            }}>{data.description}</Box>
                        </Box>
                    }

                    {
                        (selectedPage?.id === EPage.repositories)&&
                        <Box sx={{padding: '5px 35px'}}>
                            <Box sx={{fontSize: '20px', textDecoration: 'underline', color: Colors.grayedOut}}>
                                {Dictionary.description}:
                            </Box>
                            <Box sx={{
                                marginLeft: '20px',
                                fontSize: '15px',
                                whiteSpace: 'break-spaces',
                                margin: '5px 15px'
                            }}>{data.description}</Box>

                            <Box sx={{fontSize: '20px', textDecoration: 'underline', color: Colors.grayedOut}}>
                                {Dictionary.tenantName}:
                            </Box>
                            <Box sx={{
                                marginLeft: '20px',
                                fontSize: '15px',
                                whiteSpace: 'break-spaces',
                                margin: '5px 15px'
                            }}>{data.tenant?.name}</Box>

                            <Box sx={{fontSize: '20px', textDecoration: 'underline', color: Colors.grayedOut}}>
                                {Dictionary.pipeline}:
                            </Box>
                            <Box sx={{
                                marginLeft: '20px',
                                fontSize: '15px',
                                whiteSpace: 'break-spaces',
                                margin: '5px 15px'
                            }}>{data.pipeline?.name}</Box>

                            <Box sx={{fontSize: '20px', textDecoration: 'underline', color: Colors.grayedOut}}>
                                {Dictionary.branches}:
                            </Box>
                            <Box sx={{
                                marginLeft: '20px',
                                fontSize: '15px',
                                whiteSpace: 'break-spaces',
                                margin: '5px 15px'
                            }}>{data.branches?.length} {Dictionary.branches}</Box>
                        </Box>
                    }


                    {selectedPage?.id === EPage.pipelines &&
                        <Box>
                            <Box sx={{padding: '5px 35px', display: 'flex'}}>
                                <Typography sx={{textTransform: 'capitalize'}}>{Dictionary.tenantName}: </Typography>
                                <Typography sx={{marginLeft: '10px'}}>{data.tenant?.name}</Typography>
                            </Box>
                            <Box sx={{padding: '5px 35px', display: 'flex'}}>
                                <Typography sx={{textTransform: 'capitalize'}}>{Dictionary.buildType}: </Typography>
                                <Typography sx={{marginLeft: '10px'}}>{data.buildType}</Typography>
                            </Box>
                            <Box sx={{padding: '5px 35px', display: 'flex'}}>
                                <Typography sx={{textTransform: 'capitalize'}}>{Dictionary.runtime}: </Typography>
                                <Typography sx={{marginLeft: '10px'}}>{data.runtime}</Typography>
                            </Box>
                            {data.bricks &&
                                <Box sx={{padding: '5px 35px'}}>
                                    <Typography sx={{textTransform: 'capitalize'}}>{Dictionary.bricks}: </Typography>
                                    {
                                        data.bricks.map((brick, index) =>
                                            <li  key={index}
                                                 style={{display: 'flex', margin: '10px'}}>
                                                <Box component={ICONS[brick.icon]}
                                                     sx={{width: '20px', marginRight: '10px'}}/>
                                                <Typography>{brick.name}</Typography>
                                            </li>
                                        )
                                    }
                                </Box>
                            }
                        </Box>
                    }
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
