import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { AddCircle, Close, DeleteForever } from '@mui/icons-material';
import { Colors } from '../../../../../consts/colors.const';
import { Dictionary } from '../../../../../consts/dictionary';

const SelectionScrollableAreaId = 'selection-scrollable-area';

export const SelectValuesModal = ({open, onClose, currentOptions = []}) => {

    const [options, setOptions] = useState(currentOptions ? [...currentOptions] : []);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);

    const closeHandler = (closeResponse = currentOptions) => {
        onClose(closeResponse);
    }

    const addValueHandler = () => {
        if (inputValue && !inputError) {
            setOptions([...options, inputValue]);
            setInputValue('');
            setTimeout(() => {
                const listElement = document.getElementById(SelectionScrollableAreaId);
                listElement?.scrollTo(0, 10 * options.length);
            },10);
        }
    }

    return <Modal open={open}
                  onClose={() => {
                      closeHandler()
                  }}
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                  }}>
        <Box sx={{
            minWidth: '500px',
            minHeight: '650px',
            background: Colors.light,
            position: 'relative',
            borderRadius: '4px'
        }}>

            <Box sx={{
                width: '100%',
                height: '40px',
                background: Colors.dark,
                color: Colors.grayedOut,
                padding: '0 10px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: `0px 1px 3px 0px ${Colors.dark}`,
            }}>
                <Typography variant={'h6'}>
                    {options?.length ? `(${options.length})` : ''} {Dictionary.addOptionsForSelection}
                </Typography>
                <Close sx={{cursor: 'pointer', width: '15px'}}
                       onClick={() => { closeHandler(); }}/>
            </Box>

            <Box sx={{width: '100%', height: '550px', position: 'relative'}}>
                <Box id={SelectionScrollableAreaId}
                     sx={{padding: '15px', height: 'calc(100% - 60px)', overflow: 'scroll'}}>
                    {options?.map((op, index) =>
                        <Box key={index}
                             sx={{display: 'flex', alignItems: 'center', ':hover': {color: Colors.mainHighlight} }}>

                            <DeleteForever onClick={() => {
                                const newOptions = options.filter(item => item !== op);
                                setOptions(newOptions);
                                setInputError(newOptions.find(op => op === inputValue))
                            }} sx={{fontSize: '20px', color: Colors.grayedOut, cursor: 'pointer', ':hover': {color: Colors.alertColor} }}/>

                            <Typography variant={'h6'}
                                        sx={{cursor: 'default'}}>
                                {op}
                            </Typography>

                        </Box>
                    )}
                </Box>
                <Box sx={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '20px',
                    display: 'flex',
                    width: 'calc(100% - 40px)',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between'
                }}>
                    <TextField sx={{width: '80%'}}
                               error={Boolean(inputError)}
                               onKeyPress={(event) => {
                                   if (inputValue && event.key === 'Enter') {
                                       addValueHandler();
                                   }
                               }}
                               label={Dictionary.optionKey}
                               variant="standard"
                               value={inputValue}
                               onChange={(e) => {
                                   const value = e?.target?.value || '';
                                   setInputValue(value);
                                   setInputError(options?.find(op => op === value));
                               }}/>

                    <Box>
                        <AddCircle onClick={addValueHandler} sx={{
                            cursor: (inputValue && !inputError) ? 'pointer' : 'default',
                            color: (inputValue && !inputError) ? Colors.mainHighlight : Colors.grayedOut
                        }}/>
                    </Box>

                </Box>
            </Box>

            <Box sx={{
                textDecoration: 'capitalize',
                position: 'absolute',
                bottom: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button sx={{marginRight: '15px'}}
                        variant="outlined"
                        onClick={() => { closeHandler(); }}>
                    {Dictionary.cancel}
                </Button>
                <Button sx={{marginRight: '15px'}}
                        variant="contained"
                        disabled={Boolean(inputValue)}
                        onClick={() => { closeHandler(options); }}>
                    {Dictionary.submit}
                </Button>
            </Box>
        </Box>
    </Modal>
}
