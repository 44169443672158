import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Box, Modal, TextField, Divider } from '@mui/material';
import * as ICONS from '@mui/icons-material';
import { setPipelinesData } from '../../../redux/sclices/pipelines.slice';
import { setRepositoriesData } from '../../../redux/sclices/repositories.slice';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import pipelinesService from '../../../services/pipelines.service';
import repositoriesService from '../../../services/repositories.service';
import { IconSelector } from './iconSelector/iconSelector';
import { TextFieldWithRegexValidation } from '../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';
import { SettingsDefinition } from '../../common/settingsDefinition/settingsDefinition';
import { BrickSettings } from './brickSettings/brickSettings';

export const Description = 'description';
export const BrickName = 'name';
export const BrickSHName = 'id';
export const BrickIcon = 'brickIcon';
export const Settings = 'settings';

export const BricksEditor = ({onSubmit, brickItem}) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        disableSubmit:
            !brickItem?.name ||
            !brickItem?.id ||
            !brickItem?.stage,
        [BrickName]: brickItem?.name || '',
        [BrickSHName]: brickItem?.id || '',
        [BrickIcon]: brickItem?.icon,
        [Description]: brickItem?.description || '',
        [Settings]: brickItem?.settings || [],
    });

    const [open, setOpen] = useState(false);

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[BrickName]
                || !newState[BrickSHName]
                || !newState[BrickSHName]?.toString().match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [BrickName]: eventData.get(BrickName),
            [BrickSHName]: eventData.get(BrickSHName),
            [Description]: eventData.get(Description),
            [Settings]: state[Settings],
            icon: state[BrickIcon],
        });
        reloadPipelines();
        reloadRepositories();
    }

    const reloadPipelines = () => {
        pipelinesService.loadPipelines().subscribe((pipelines) => {
            dispatch(setPipelinesData(pipelines));
        });
    }

    const reloadRepositories = () => {
        repositoriesService.loadRepositories().subscribe((repos) => {
            dispatch(setRepositoriesData(repos));
        });
    }


    const handleClose = () => {
        setOpen(false);
    }

    return(<Box component='form'
               onSubmit={submitHandler}
                sx={{
                    display: 'flex',
                    height: '100%'
                }}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <TextField required={true}
                       value={state[BrickName]}
                       onInput={(e) => {
                           inputChangeHandler(BrickName, e.target.value);
                       }}
                       name={BrickName}
                       sx={{
                           paddingBottom: '10px',
                           maxWidth: '500px'
                       }}
                       label={Dictionary.brickName}
                       variant="filled" />

            <TextFieldWithRegexValidation value={state[BrickSHName]}
                                          disabled={Boolean(brickItem)}
                                          label={Dictionary.brickMachineName}
                                          onChange={(value) => {
                                              inputChangeHandler(BrickSHName, value);
                                          }}
                                          onError={(value) => {
                                              inputChangeHandler(BrickSHName, value);
                                          }}
                                          name={BrickSHName}/>

            <TextField required={false}
                       value={state[Description]}
                       onInput={(e) => {
                           inputChangeHandler(Description, e.target.value);
                       }}
                       name={Description}
                       sx={{
                           paddingBottom: '10px',
                           maxWidth: '500px'
                       }}
                       label={Dictionary.description}
                       multiline
                       rows={4}
                       variant="filled"
            />

            <Button variant='outlined' sx={{
                paddingBottom: '10px',
                maxWidth: '500px'
            }} onClick={() => {
                setOpen(true);
            }}>{state[BrickIcon] ? Dictionary.changeIcon : Dictionary.addIcon}
                {state[BrickIcon] &&
                    <Box component={ICONS[state[BrickIcon]]} sx={{
                        position: 'absolute',
                        top: '7px',
                        right: '10px'
                    }}/>}
            </Button>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                    mt: 3,
                    mb: 2,
                    width: '500px',
                    background: state.disableSubmit ? Colors.disableBackground : '',
                    color: state.disableSubmit ? Colors.grayedOut : '',
                    ':hover': {
                        background: state.disableSubmit ? Colors.disableBackground : '',
                    }
                }}>
                {Dictionary.submit}
            </Button>

            <Modal open={open}
                   sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box>
                    <IconSelector onClose={handleClose} onSelect={(icon) => {
                        handleClose();
                        inputChangeHandler(BrickIcon, icon);
                    }}/>
                </Box>
            </Modal>
        </Box>

        <Divider orientation="vertical"
                 flexItem
                 sx={{margin: '0 30px'}}/>

        <Box sx={{width: '250'}}>
            <SettingsDefinition currentSettings={state[Settings]} onSubmit={(newSetting) => {
                inputChangeHandler(Settings, [...state[Settings], newSetting]);
            }}/>
        </Box>

        <Divider orientation="vertical"
                 flexItem
                 sx={{margin: '0 30px'}}/>

        <BrickSettings settings={state[Settings]}
                       onDelete={(setting) => {
                           setState({
                               ...state,
                               [Settings]: state[Settings].filter(set => set !== setting)
                           });
                       }}/>
    </Box>)
}
