import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { addAlert, removeAlert } from '../../../redux/sclices/alert.slice';
import { Dictionary, DictionaryWithParam } from '../../../consts/dictionary';
import { LoadingStatus } from '../../../enums/loadingStatus.enum';
import { AlertType } from '../../../consts/alert.type';
import { EPage } from '../../../enums/pages.enum';
import { InnerHeader } from '../innerHeader/innerHeader';
import { SHSkeletonLoadingPage } from '../SHSkeletonLoadingPage';
import { Grid } from './grid/grid';
import { TenantsEditor } from '../../tenants/tenantsEditor/tenantsEditor';
import { PipelineEditor } from '../../pipelines/pipelineEditor/pipelineEditor';
import { BricksEditor } from '../../bricks/bricksEditor/bricksEditor';
import { RepositoriesEditor } from '../../repositories/repositoriesEditor/repositoriesEditor';
import { TeamsEditor } from '../../teams/teamsEditor/teamsEditor';
import './page.css';

let __alertId;
let __subs = [];

export const Page = ({pageDictionary, data, bulkDeleteMethod,
                      deleteSingleItem, updateDispatchMethod, createMethod,
                      editItemMethod, loadMethod, setDataDispatchMethod}) => {

    const dispatch = useDispatch();

    const selectedPage = useSelector((state) => {
        return state.pages?.currentPage;
    });

    const viewStateOptions = {
        page: {title: pageDictionary.name, id: 1},
        create: {title: pageDictionary.create, id: 2},
        edit: {title: pageDictionary.edit, id: 3}
    };

    const [state, setState] = useState({
        breadcrumbs: [viewStateOptions.page],
        loadingStatus: data ? LoadingStatus.Succeed : LoadingStatus.InProgress
    });

    const [itemToEdit, setItemToEdit] = useState({});

    useEffect(() => {
        /*if (!data || data.tableColumns?.length === 0) {*/
            __subs = [...__subs, loadData()]
        /*}*/

        return () => {
            __subs.forEach(s => s.unsubscribe());
            dispatch(removeAlert(__alertId));
            __subs = [];
            __alertId = undefined;
        }
    }, []);

    const loadData = (alertId) => {
        dispatch(removeAlert(alertId));
        const newId = Date.now();
        setState({...state, loadingStatus: LoadingStatus.InProgress});
        __alertId = newId;
        return loadMethod().subscribe(
            (response) => {
                setState({
                    ...state,
                    loadingStatus: LoadingStatus.Succeed,
                });
                dispatch(setDataDispatchMethod({
                    list: response?.list?.map(item => {
                        return {...item, check: false}
                    }),
                    tableColumns: response?.tableColumns
                }))
            },
            () => {
                setState({
                    ...state,
                    loadingStatus: LoadingStatus.Failed,
                });
                dispatchAlert(pageDictionary.loadDataFailure,
                    Dictionary.alert.pleaseTryAgain,
                    newId,
                    () => {
                        dispatch(removeAlert(newId));
                        __subs = [...__subs, loadData(newId)];
                    });
            })
    }

    const dispatchAlert = (title, subTitle, id, callback) => {
        dispatch(addAlert({
            severity: AlertType.error,
            title,
            subTitle,
            id,
            action: callback ? {
                icon: <ReplayIcon sx={{
                    width: '15px',
                    height: '15px'
                }}/>,
                tooltip: Dictionary.tryAgain,
                callback
            } : undefined
        }));
    }

    const setView = (index) => {
        setState({
            ...state,
            breadcrumbs: state.breadcrumbs.slice(0, index + 1),
        })
    }

    const editItem = (item) => {
        setItemToEdit(item);
        viewStateOptions.edit.title = `edit ${item.name}`
        setState({
            ...state,
            breadcrumbs: [viewStateOptions.page, viewStateOptions.edit]
        })
    }

    const navToPage = () => {
        const _state = {
            ...state,
            breadcrumbs: [viewStateOptions.page],
            loadingStatus: LoadingStatus.InProgress
        };
        setState(_state);
        return _state;
    }

    const handleEditItem = (modifiedItem) => {
        const _state = navToPage();
        const submitItem = {
            ...itemToEdit,
            ...modifiedItem,
            id: itemToEdit.id
        };
        delete submitItem.check
        __subs.push(editItemMethod(submitItem).subscribe((item) => {
                if (item) {
                    dispatch(updateDispatchMethod({
                        current: item,
                        previous: itemToEdit
                    }));
                }
                setState({..._state, loadingStatus: LoadingStatus.Succeed});
            },
            (err) => {
                console.log(err);
                dispatchAlert(
                    pageDictionary.updateFailure,
                    '', Date.now());
                setState({..._state, loadingStatus: state.loadingStatus});
            }));
    }

    const handleCreateItem = (newItemInfo) => {
        const _state = navToPage();
        __subs.push(createMethod(newItemInfo).subscribe(
            () => {
                loadMethod().subscribe((data) => {
                    handleDataModification(data, _state);
                })
            },
            () => {
                dispatchAlert(DictionaryWithParam(pageDictionary.createFailure, {ITEM_NAME: newItemInfo.name}), '', Date.now());
                setState({..._state, loadingStatus: state.loadingStatus});
            }
        ));
    }

    const handleDeleteSingleItem = (rowItem) => {
        const _state = navToPage();
        __subs.push(deleteSingleItem(rowItem).subscribe(
            (data) => {
                handleDataModification(data, _state);
            },
            () => {
                dispatchAlert(DictionaryWithParam(pageDictionary.deleteFailure, {ITEM_NAME: rowItem.name}), '', Date.now());
                setState({..._state, loadingStatus: state.loadingStatus});
            }
        ));
    }

    const handleDataModification = (_data, _state) => {
        const newData = _data;
        dispatch(setDataDispatchMethod({
            list: newData?.list?.map(item => {
                return {...item, check: false}
            }),
            tableColumns: newData?.tableColumns
        }));

        setState({..._state, loadingStatus: LoadingStatus.Succeed});
    }

    const reload = () => {
        dispatch(removeAlert(__alertId));
        __subs = [...__subs, loadData(__alertId)];
    }

    const deleteBulk = (items = []) => {
        if (items.length === 1) {
            handleDeleteSingleItem(items[0]);
            return;
        }
        const _state = navToPage();
        __subs.push(bulkDeleteMethod(items).subscribe(
            (data) => {
                handleDataModification(data, _state)
            },
            () => {
                dispatchAlert(
                    DictionaryWithParam(pageDictionary.deleteBulkFailure,
                        {NUM_OF_ITEMS: items.length}),
                    '', Date.now());
                setState({..._state, loadingStatus: state.loadingStatus});
            }
        ))
    }

    return (
        <section className='page-wrapper'>
            <InnerHeader title={pageDictionary.name}
                         breadcrumbs={{
                             items: state.breadcrumbs,
                             onItemClick: setView
                         }}
                         reload={(
                             state.loadingStatus !== LoadingStatus.InProgress &&
                             state.breadcrumbs?.last()?.id === viewStateOptions.page.id) ? {
                             tooltip: pageDictionary.reload,
                             callback: reload
                         } : undefined}>
            </InnerHeader>

            {state.loadingStatus === LoadingStatus.InProgress && <>
                <SHSkeletonLoadingPage label={pageDictionary.loading}/>
            </>}

            {state.loadingStatus === LoadingStatus.Succeed && <Box sx={{height: '100%'}}>
                {state.breadcrumbs?.last()?.id === viewStateOptions.page.id &&
                    <Grid allowBulkActions={selectedPage?.allowBulkAction}
                          allowDeleteItem={selectedPage?.allowDeleteItem}
                          pageID={selectedPage?.id}
                          tableData={data}
                          contextDictionary={pageDictionary}
                          editItem={editItem}
                          bulkDelete={deleteBulk}
                          deleteSingleItem={handleDeleteSingleItem}
                          onCreateNewNavButton={() => {
                                   setState({
                                       ...state,
                                       breadcrumbs: [...state.breadcrumbs, viewStateOptions.create]
                                   })
                               }}/>
                }
                {selectedPage?.id === EPage.tenants && <>
                    {state.breadcrumbs?.last()?.id === viewStateOptions.create?.id &&
                        <TenantsEditor onSubmit={handleCreateItem}/>
                    }

                    {state.breadcrumbs?.last()?.id === viewStateOptions.edit?.id &&
                        <TenantsEditor onSubmit={handleEditItem}
                                       tenant={itemToEdit}/>
                    }
                </>}

                {selectedPage?.id === EPage.pipelines && <>
                    {state.breadcrumbs?.last()?.id === viewStateOptions.create?.id &&
                       <PipelineEditor onSubmit={handleCreateItem}/>
                    }

                    {state.breadcrumbs?.last()?.id === viewStateOptions.edit?.id &&
                        <PipelineEditor pipeline={itemToEdit} onSubmit={handleEditItem}/>
                    }
                </>}

                {selectedPage?.id === EPage.bricks && <>
                    {state.breadcrumbs?.last()?.id === viewStateOptions.create?.id &&
                        <BricksEditor onSubmit={handleCreateItem}/>
                    }
                    {state.breadcrumbs?.last()?.id === viewStateOptions.edit?.id &&
                        <BricksEditor brickItem={itemToEdit} onSubmit={handleEditItem}/>
                    }
                </>}


                {selectedPage?.id === EPage.repositories && <>
                    {state.breadcrumbs?.last()?.id === viewStateOptions.create?.id &&
                        <RepositoriesEditor onSubmit={handleCreateItem}/>
                    }
                    {state.breadcrumbs?.last()?.id === viewStateOptions.edit?.id &&
                        <RepositoriesEditor repository={itemToEdit} onSubmit={handleEditItem}/>
                    }
                </>}

                {selectedPage?.id === EPage.teams && <>
                    {state.breadcrumbs?.last()?.id === viewStateOptions.create?.id &&
                        <TeamsEditor onSubmit={handleCreateItem}/>
                    }
                    {state.breadcrumbs?.last()?.id === viewStateOptions.edit?.id &&
                        <TeamsEditor team={itemToEdit} onSubmit={handleEditItem}/>
                    }
                </>}

            </Box>}

        </section>
    );
}
