import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

export const SHTableRowActions = ({row, actions}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <MoreVertIcon sx={{cursor: 'pointer'}} aria-haspopup={true} onClick={handleClick}/>
            <Menu transformOrigin={{vertical: 'center', horizontal: 'right'}}
                  open={!!anchorEl}
                  id='actions-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  onClose={handleClose}>
                {actions.map((action, index) =>
                    <MenuItem disabled={action.disabled}
                              key={index} onClick={() => {
                        action.callback(row);
                        handleClose();
                    }}>{action.name}</MenuItem>)}
            </Menu>
        </>
    );
}
