import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../../redux/sclices/pages.slice';
import { PageIcon } from '../../../../consts/pages';
import { Dictionary } from '../../../../consts/dictionary';
import appLogo from '../../../../assets/hawk-blue.png';
import './sideMenu.css';
import * as style from './style';

export const SideMenu = () => {

    const dispatch = useDispatch();

    const pages = useSelector((state) => {
        return state.pages.allPages;
    });

    return (
        <section style={style.sideMenu}>

            <section style={style.sideMenuHeader}>
                <img style={style.sideMenuAppLogo} src={appLogo} alt={Dictionary.appName}/>
                <label style={style.appName}>{Dictionary.appName}</label>
            </section>

            <Box style={style.sideMenuList} className='side-menu-list'>
                <Typography variant="caption"
                            gutterBottom style={style.sideMenuListHeader}>
                    {Dictionary.mainMenuPagesTitle}
                </Typography>
                {pages.map((listItem, index) => (
                    <Box key={index}
                         onClick={() => {
                             dispatch(setCurrentPage(listItem));
                         }}
                         sx={window.location.pathname.includes(listItem.name) ?
                             {...style.sideMenuListItem, ...style.sideMenuListItemSelected} : style.sideMenuListItem
                         }>
                        <section style={style.sideMenuListItemIcon}>
                            {PageIcon[listItem.name]}
                        </section>
                        <section style={style.sideMenuListItemName}>
                            {listItem.name}
                        </section>
                    </Box>
                ))}
            </Box>

        </section>);
}
