import React from 'react';
import Box from '@mui/material/Box';
import { FmdBad, HighlightOffRounded } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { Colors } from '../../../consts/colors.const';
import { AlertType } from '../../../consts/alert.type';

const SX = {
    [AlertType.info]: {
        background: Colors.dark,
        color: Colors.light,
        shadow: Colors.dark,
    },
    [AlertType.warning]: {
        background: Colors.warining,
        color: Colors.dark,
        shadow: Colors.dark,
    }
}

export const TabMessage = ({message, onClose, type = AlertType.info }) => {

    return <>
        <Collapse sx={{
            background: SX[type].background,
            color: SX[type].color,
            position: 'absolute',
            top: '10px',
            left: '10px',
            overflow: 'hidden',
            whiteSpace: 'pre',
            boxShadow: `2px 3px 5px 0px ${SX[type].shadow}`,
        }}
                  orientation="horizontal" in={Boolean(message)}>
            <Box sx={{padding: '10px', display: 'flex', marginRight: '15px',}}>
                <FmdBad sx={{marginRight: '10px'}}/>
                {message}
                {onClose && <HighlightOffRounded sx={{
                    width: '15px',
                    position: 'absolute',
                    right: '3px',
                    top: '0',
                    cursor: 'pointer'
                }} onClick={onClose}/>
                }
            </Box>
        </Collapse>
    </>
}
