import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableColumns: [],
    list: [],
}


export const pipelinesSlice = createSlice({
    name: 'pipelines',
    initialState: initialState,
    reducers: {
        setPipelinesData: (state, action) => {
            state.tableColumns = action.payload.tableColumns || [];
            state.list = action.payload.list || [];
        },
        updatePipeline: (state, action) => {
            const updatedPipeline = action.payload.current;
            const previousPipelineData = action.payload.previous;
            state.list = state.list.map(pipeline => {
                if (pipeline.id === previousPipelineData.id) {
                    return {
                        ...pipeline,
                        ...updatedPipeline
                    }
                }
                return pipeline;
            });
        },
        reset: (state) => {
            state.loginData = {
                tableColumns: [],
                list: [],
            };
        }
    },

});

export const { setPipelinesData, updatePipeline, reset } = pipelinesSlice.actions;

export default pipelinesSlice.reducer;
