import { from } from 'rxjs';
import store from '../redux/store';
import { AuthApi, BaseCognitoAPI } from '../consts/apiList';
import { setLoginData } from '../redux/sclices/login.slice';


const successCodes = [200, 201, 204]
class HttpService {

    async refreshToken(refresh_token) {

        const params =
            'grant_type=refresh_token' +
            `&client_id=${AuthApi.authorizeParams.client_id}` +
            `&redirect_uri=${AuthApi.authorizeParams.redirect_uri}` +
            `&refresh_token=${refresh_token}`

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params
        };

        return await this._fetch(AuthApi.token, requestOptions);
    }

    async getAuthentication() {
        const loginData = store?.getState()?.login?.loginData;
        const expiration = loginData?.expireTime;

        if (expiration < Date.now()) {
            const refreshToken = store?.getState()?.login?.loginData?.tokens?.refresh_token;
            const data = await this.refreshToken(refreshToken);
            store.dispatch(setLoginData({
                ...loginData,
                expireTime: data.expires_in * 1000 + Date.now(),
                tokens: {
                    ...loginData.tokens,
                    ...data,
                }
            }));
        }
        const token_type = store?.getState()?.login?.loginData?.tokens?.token_type;
        const id_token = store?.getState()?.login?.loginData?.tokens?.id_token;
        return `${token_type} ${id_token}`;

    }

    async _fetch(endpoint, requestOptions) {
        if (!endpoint.includes(BaseCognitoAPI)) {
            requestOptions.headers['Authorization'] = await this.getAuthentication(endpoint);
        }

        return await fetch(endpoint, requestOptions)
            .then((response) => {
                if (!successCodes.includes(response.status)) {
                    this.throwError(response);
                }
                return response?.clone()?.json();
            })
            .catch(error => {
                this.throwError(error)
            })
    }

    _get(endpoint, params = {}, headers = {}) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers},
        };

        const paramsStr = this.getParameterStr(params);

        return from(this._fetch(`${endpoint}${paramsStr}`, requestOptions));
    }

    _post(endpoint, params, headers = {}) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers},
            body: typeof params === 'object' ? JSON.stringify(params) : params
        };

        return from(this._fetch(endpoint, requestOptions));
    }

    _delete(endpoint, params = {}, headers = {}) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...headers},
            body: JSON.stringify(params)
        };

        return from(this._fetch(endpoint, requestOptions));
    }

    _put(endpoint, params = {}, headers = {}) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...headers},
            body: JSON.stringify(params)
        };

        return from(this._fetch(endpoint, requestOptions));
    }

    throwError(error) {
        throw new Promise((resolve, reject) => {
            reject(error);
        });
    }

    getParameterStr(params = {}) {
        let paramsStr = '';
        Object.keys(params).forEach((paramName, index) => {
            paramsStr += index === 0 ? '?' : '&';
            paramsStr+=`${paramName}=${params[paramName]}`;
        });
        return paramsStr;
    }
}

export default new HttpService();
