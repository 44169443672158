import React, { useState } from 'react';
import { Box , FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import { HighlightOffRounded, Search } from '@mui/icons-material';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';

export const SHSearch = ({searchHandler, addSearchLabel = true, color = Colors.dark}) => {

    const [searchText, setSearchText] = useState('')

    return <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <FormControl variant="standard">
            {addSearchLabel &&
                <InputLabel htmlFor="input-search"
                            sx={{textTransform: 'capitalize'}}>
                    {Dictionary.search}
                </InputLabel>}
            <Input sx={{'::after': {borderBottom: '2px solid rgb(35, 48, 68)'} }}
                   value={searchText}
                   onInput={(input) => {
                       setSearchText(input?.target?.value);
                       if (searchHandler) {
                           searchHandler(input?.target?.value);
                       }
                   }}
                id="input-search"
                startAdornment={
                    <InputAdornment position="start">
                        <Search sx={{color: color}}/>
                    </InputAdornment>
                }
                   endAdornment={
                       searchText && <InputAdornment position='end'>
                           <HighlightOffRounded sx={{
                               width: '15px',
                               height: '15px',
                               cursor: 'pointer',
                               color: 'lightgray',
                               ':hover': {
                                   color: 'gray'
                               }}}
                               onClick={() => {
                                   setSearchText('');
                                   searchHandler('');
                               }}/>
                       </InputAdornment>
                   }
            />
        </FormControl>
    </Box>
}
