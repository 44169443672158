import React, { useState } from 'react';
import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { HighlightOffRounded, KeyboardDoubleArrowRight } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SettingsField } from '../../../../enums/settingsField.enum';
import { Colors } from '../../../../consts/colors.const';

const ENTER = 'Enter';

export const AddSettings = ({options = [], onSubmit, onCancel}) => {
    const [addItem, setAddItem] = useState({
        itemObj: null,
        itemValue: '',
    });

    const handleSubmit = (submitData) => {
        onSubmit(submitData);
        setAddItem({
            itemObj: null,
            itemValue: '',
        })
    }

    const handleAddItemKey = (item) => {
        setAddItem({...addItem, itemObj: item, itemValue: ''});
    }

    const handleAddItemValue = (value) => {
        setAddItem({...addItem, itemValue: value});
    }

    return(

                <>
                    {options.length > 0 &&
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        {onCancel &&
                            <HighlightOffRounded sx={{
                                width: '15px',
                                height: '15px',
                                alignSelf: 'flex-start',
                                cursor: 'pointer'
                            }} onClick={onCancel}/>
                        }
                        <FormControl variant="standard" sx={{ width: 150 }}>
                            <Select
                                autoFocus={true}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={addItem.itemObj || ''}
                                onChange={(e) => {
                                    handleAddItemKey(e?.target?.value);
                                }}
                            >
                                {options.map((op) => (
                                    <MenuItem
                                        key={op.name}
                                        value={op}>
                                        {op.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <KeyboardDoubleArrowRight sx={{alignSelf: 'center'}}/>
                        {addItem?.itemObj?.type === SettingsField.boolean &&
                            <Box sx={{marginLeft: '15px', width: '300px'}}>
                                <FormControl>
                                    <RadioGroup
                                        onKeyDown={event => {
                                            const {key} = event;
                                            if (key === ENTER && addItem.itemValue) {
                                                handleSubmit({...addItem.itemObj, value: addItem.itemValue});
                                            }
                                        }}
                                        onChange={(e, v) => {
                                            handleAddItemValue(v)
                                        }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value={true}
                                                          control={<Radio sx={{padding: '0'}} />}
                                                          checked={addItem.itemValue === 'true'}
                                                          label="True" />
                                        <FormControlLabel value={false}
                                                          control={<Radio sx={{padding: '0'}}/>}
                                                          checked={addItem.itemValue === 'false'}
                                                          label="False" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        }

                        {addItem?.itemObj?.type === SettingsField.text &&
                            <TextField variant="standard"
                                       onKeyPress={(event) => {
                                           if (addItem.itemObj && event.key === ENTER) {
                                               handleSubmit({...addItem.itemObj, value: addItem.itemValue});
                                           }
                                       }}
                                       sx={{width: '300px', marginLeft: '5px'}}
                                       value={addItem.itemValue}
                                       onChange={(e) => {
                                           handleAddItemValue(e.target.value);
                                       }}/>
                        }

                        {addItem?.itemObj?.type === SettingsField.select &&
                            <FormControl variant="outlined"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                         }}
                                         sx={{
                                             width: '300px',
                                         }}>
                                <Select variant={'standard'}
                                        value={addItem.itemValue}
                                        onChange={(e) => {
                                            handleAddItemValue(e.target.value);
                                        }}>

                                    {addItem.itemObj?.selectOptions?.map((op, key) =>
                                        <MenuItem
                                            key={key}
                                            value={op}>
                                            {op}
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        }

                        <Box sx={{width: '30px'}}>
                            {(addItem.itemObj && addItem.itemValue) &&
                                <AddCircleOutlineIcon
                                    onClick={() => {
                                        handleSubmit({...addItem.itemObj, value: addItem.itemValue, touched: true});
                                    }}
                                    sx={{

                                        color: Colors.mainHighlight,
                                        alignSelf: 'center',
                                        cursor: 'pointer'
                                    }}/>
                            }
                        </Box>
                    </Box>
                    }
                </>
    );
}
