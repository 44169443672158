import React from 'react';
import { Box } from '@mui/material';
import { PipelineBricks } from './pipelineBricks/pipelineBricks';
import { BricksBank } from './bricksBank/bricksBank';

export const PipelineBricksSelector = ({bricks, onChange}) => {

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '25px 0 0 10px',
            boxSizing: 'border-box'
        }}>
            <Box sx={{
                width: '100%',
                height: '30%'
            }}>
                <PipelineBricks bricks={bricks}
                                onChange={onChange}/>
            </Box>
            <Box sx={{
                width: '100%',
                height: '40%',
                maxHeight: '400px',
                overflowY: 'scroll',
                paddingTop: '25px',
                boxSizing: 'border-box'
            }}>
                <BricksBank/>
            </Box>
        </Box>)
}
